import React, { useState, useEffect } from 'react';
import '../../../../styles/style.css';
import '../../../../styles/employee.css';
import AddEmployee from '../../departments/super-admin/employees/AddEmployee';
import { API_URL } from '../../../../utils/config';
import ConfirmedEmployees from '../staff/ConfirmedEmployees';
import UnconfirmedEmployees from '../staff/UnconfirmedEmployees';
import CustomCsvDownload from '../CustomCsvDownload';
import { employeeHeaders, filename } from '../../../../utils/field-support/installations/helpers';

const AdminEmployeeBody = (props) => {
  const [state, setState] = useState({
    keyword: '',
    value: 'confirmed',
    confirmed: [],
    filteredConfirmed: [],
    unconfirmed: [],
    filteredUnconfirmed: [],
    isLoading: false,
    employees: [],
  });



  const fetchUnconfirmedEmployees = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const response = await fetch(`${API_URL}/employeeReg?confirmed=false`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      const data = await response.json();

      setState((previousState) => ({
        ...previousState,
        employees: data,
        filteredUnconfirmed: data,
        unconfirmed: data,
        isLoading: false,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        error,
        isLoading: false,
      }));
    }
  };

  const fetchEmployees = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const response = await fetch(`${API_URL}/employeeReg?confirmed=true`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      const data = await response.json();
      setState((prevState) => ({
        ...prevState,
        employees: data,
        confirmed: data,
        filteredConfirmed: data,
        isLoading: false,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        error,
        isLoading: false,
      }));
    }
  };

  useEffect(() => {
    fetchEmployees();
    fetchUnconfirmedEmployees();
  }, []);

  const onChangeSelect = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      keyword: '',
      value: e.target.value,
    }));
  };

  const searchEmployees = (e) => {
    const searchValue = e.target.value;

    const { value, unconfirmed, confirmed } = state;

    let filteredConfirmed = confirmed;
    let filteredUnconfirmed = unconfirmed;
    let keyword = searchValue;

    if (searchValue.length) {
      keyword = searchValue.toLowerCase();
      if (value === 'confirmed') {
        filteredConfirmed = confirmed.filter((employee) => {
          return employee.officialEmail.includes(keyword);
        });
      } else if (value === 'unconfirmed') {
        filteredUnconfirmed = unconfirmed.filter((employee) => {
          return employee.email.includes(keyword);
        });
      }
    }

    setState((prevState) => ({
      ...prevState,
      filteredConfirmed,
      filteredUnconfirmed,
      keyword,
    }));
  };

  const role_id = sessionStorage.getItem('roleId');

  return (
    <div className="pt-5 mt-5">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 mt-3 mb-3 search d-flex">
            <div className="d-flex employ inner-addon left-addon">
              <input
                className="form-control w-75 mt-1 mr-auto"
                value={state.keyword}
                onChange={(e) => searchEmployees(e)}
                id="iconified"
                type="text"
                placeholder="Search"
                style={{ marginLeft: '1rem' }}
              />
              <span className="fa fa-search" />
              &nbsp; &nbsp; &nbsp;
              {role_id > 2 && role_id != 6 && (
                <p className="op copy-font d-flex">
                  <small className="mt-2 "> Filter By: </small>
                  <span>
                    <select className="form-control" defaultChecked={state.value} onChange={(e) => onChangeSelect(e)}>
                      <option value="confirmed">Confirmed Employees</option>
                      <option value="unconfirmed">Unconfirmed Employees</option>
                    </select>
                  </span>
                </p>
              )}
            </div>
          </div>
          <AddEmployee />
          {state.value === 'confirmed' ? (
            <div className="col-12 d-flex m-2">
              <CustomCsvDownload
                data={state.confirmed || []}
                headers={employeeHeaders}
                filename={filename(`Employee`)}
                classname="btn btn-green shadow-md"
                textcolor="white"
                text="Download Data"
              />
            </div>
          ) : (
            <div className="col-12 d-flex m-2">
              <CustomCsvDownload
                data={state.unconfirmed || []}
                headers={employeeHeaders}
                filename={filename(`Employee`)}
                classname="btn btn-green shadow-md"
                textcolor="white"
                text="Download Data"
              />
            </div>
          )}

          <div className="card x-panel">
            <div className="table-responsive content">
              <table className="table col-lg-12 col-md-12 col-sm-12">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Email</th>
                    <th>Date of resumption</th>

                    <th>Action</th>
                  </tr>
                </thead>

                {state.value === 'confirmed' ? (
                  <ConfirmedEmployees
                    confirmed={state.keyword.length ? state.filteredConfirmed : state.confirmed}
                    isLoading={state.isLoading}
                  />
                ) : (
                  <UnconfirmedEmployees
                    unconfirmed={state.keyword.length ? state.filteredUnconfirmed : state.unconfirmed}
                    isLoading={state.isLoading}
                  />
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminEmployeeBody;
