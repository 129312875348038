import React from 'react';
import { Route, Switch } from 'react-router-dom';
import App from '../App';
import ForgotPassword from '../components/staff/shared/auth/container/ForgotPassword';
import CustomerSupportCustomers from '../components/staff/departments/customer-support/customers/CustomerSupportCustomers';
import ResetPassword from '../components/staff/shared/auth/container/ResetPassword';
import CustomerSupportOverview from '../components/staff/departments/customer-support/CustomerSupportOverview';
import BusinessDevelopmentOverview from '../components/staff/departments/business-development/BusinessDevelopmentOverview';
import BusinessDevelopmentInvoice from '../components/staff/departments/business-development/invoice/BusinessDevelopmentInvoice';
import CustomerRetention from '../components/staff/departments/customer-support/retention/CustomerRetention';
import CustomerSupportLowDataUsage from '../components/staff/departments/customer-support/low-data-usage/CustomerSupportLowDataUsage';
import CustomerSupportUsageHistory from '../components/staff/departments/customer-support/usage-history/CustomerSupportUsageHistory';
import SaleOveriew from '../components/staff/departments/sales/SalesOverview';
import CustomerSupportBaseStations from '../components/staff/departments/customer-support/basestations/CustomerSupportBaseStation';
import CustomerSupportBaseStationData from '../components/staff/departments/customer-support/basestations/CustomerSupportBaseStationData/CustomerSupportBaseStationData';
import CustomerSupportSchedule from '../components/staff/departments/customer-support/schedule/CustomerSupportSchedule';
import CustomerSupportEscalate from '../components/staff/departments/customer-support/escalate/CustomerSupportEscalate';
import SalesBaseStation from '../components/staff/departments/sales/basestations/SalesBaseStation';
import SalesCustomers from '../components/staff/departments/sales/customers/SalesCustomers';
import SalesCustomerInvoice from '../components/staff/departments/sales/customers-invoice/SalesCustomerInvoice';
import SalesExistingInvoice from '../components/staff/departments/sales/existing-invoice/SalesExistingInvoice';
import AccountsOverview from '../components/staff/departments/accounts/AccountsOverview';
import NetworkOperationsOverview from '../components/staff/departments/noc/NetworkOperationsOverview';
import NetworkOperationsBaseStation from '../components/staff/departments/noc/basestations/NetworkOperationsBaseStation';
import NetworkOperationsAccessPoints from '../components/staff/departments/noc/access-points/AccessPoints';
import ProcurementOverview from '../components/staff/departments/procurement/ProcurementOverview';
import ProcurementInventories from '../components/staff/departments/procurement/inventories/ProcurementInventory';
import ProcurementVehicles from '../components/staff/departments/procurement/vehicles/ProcurementVehicles';
import ProcurementEscalate from '../components/staff/departments/procurement/escalate/ProcurementEscalate';
import SalesLead from '../components/staff/departments/sales/leads/SalesLeads';
import CustomerSupportLeads from '../components/staff/departments/customer-support/leads/CustomerSupportLeads';
import WifiCallPlan from '../components/staff/departments/wificall/plans/WifiCallPlan';
import WifiCallDirectory from '../components/staff/departments/wificall/directory/WifiCallDirectory';
import FrequencyHistory from '../components/staff/departments/wificall/FrequencyHistory';
import Wallboard from '../components/staff/departments/wificall/wallboard/Wallboard';
import WalletRefill from '../components/staff/departments/wificall/WalletRefill';
import ResearchOverview from '../components/staff/departments/research-and-development/ResearchOverview';
import NotFound from '../components/staff/shared/NotFound';
import AccountEmployees from '../components/staff/departments/accounts/employees/AccountsEmployees';
import AccountsPurchaseOrder from '../components/staff/departments/accounts/purchase-order/AccountsPurchaseOrder';
import CFOProcurementPurchaseOrder from '../components/staff/departments/accounts/cfo/procurement/CFOProcurementPurchaseOrder';
import DepartmentsPurchaseOrder from '../components/staff/departments/accounts/purchase-order/departmentsPO/DepartmentsPurchaseOrder';
import FirsTransactions from '../components/staff/departments/accounts/firs/AccountsFirsTransactions';
import FirsReverseTransactions from '../components/staff/departments/accounts/firs/AccountsReverseFirsTransactions';
import FirsReversedTransactions from '../components/staff/departments/accounts/firs/AccountsReversedFirsTransactions';
import ApprovedPurchaseOrder from '../components/staff/departments/accounts/purchase-order/approvedPO/ApprovedPurchaseOrder';
import ProcurementApprovedPO from '../components/staff/departments/procurement/purchase-order/approvedPO/ProcurementApprovedPO';
import AccountInventories from '../components/staff/departments/accounts/inventories/AccountsInventories';
import AccountsVehicles from '../components/staff/departments/accounts/vehicles/AccountsVehicles';
import AccountsBaseStations from '../components/staff/departments/accounts/basestations/AccountsBaseStations';
import AccountsEscalate from '../components/staff/departments/accounts/escalate/AccountsEscalate';
import NetworkPublicIP from '../components/staff/departments/noc/public-ips/NetworkPublicIP';
import NetworkOperationsVoltage from '../components/staff/departments/noc/voltage/NetworkOperationsVoltage';
import ResearchProject from '../components/staff/departments/research-and-development/projects/Project';
import CustomerSupportActiveCustomersPerZone from '../components/staff/departments/customer-support/zones/active-zones/CustomerSupportActiveCustomersPerZone';
import ResearchFeatureRequest from '../components/staff/departments/research-and-development/features/FeatureRequest';
import IssueReport from '../components/staff/departments/research-and-development/issues/IssueReport';
import ResearchEscalate from '../components/staff/departments/research-and-development/escalate/ResearchEscalate';
import NewProductOverview from '../components/staff/departments/new-products/NewProductOverview';
import ProductModal from '../components/staff/departments/new-products/ProductModal';
import CustomerSupportCallog from '../components/staff/departments/customer-support/call-logs/CustomerSupportCallog';
import NetworkOperationsBaseStationMonitoring from '../components/staff/departments/noc/basestations/NetworkOperationsBasestationMonitoring';
import NetworkOperationsFrequencyLog from '../components/staff/departments/noc/frequency-log/NetworkOperationsFrequencyLog';
import ReportIssue from '../components/staff/departments/accounts/issues/ReportIssue';
import RequestFeature from '../components/staff/departments/accounts/features/RequestFeature';
import CustomerSupportReportIssue from '../components/staff/departments/customer-support/issues/CustomerSupportReportIssue';
import NewProductIssue from '../components/staff/departments/new-products/issues/NewProductIssue';
import NewProductFeature from '../components/staff/departments/new-products/features/NewProductFeature';
import NetworkOperationsIssue from '../components/staff/departments/noc/issues/NetworkOperationsIssue';
import NetworkOperationsFeature from '../components/staff/departments/noc/features/NetworkOperationsFeature';
import ProcurementIssue from '../components/staff/departments/procurement/issues/ProcurementIssue';
import ProcurementFeature from '../components/staff/departments/procurement/features/ProcurementFeature';
import ReleaseReport from '../components/staff/shared/release-report/ReleaseReport';
import ProcurementRetrievals from '../components/staff/departments/procurement/retrieval/ProcurementRetrievals';
import ProcurementInstallation from '../components/staff/departments/procurement/installations/ProcurementInstallation';
import ProcurementTracklist from '../components/staff/departments/procurement/tracklist/ProcurementTracklist';
import DepartmentalPurchaseOrder from '../components/staff/departments/procurement/purchase-order/departments/DepartmentalPurchaseOrder';
import ProcurementPurchaseOrder from '../components/staff/departments/procurement/purchase-order/ProcurementPurchaseOrder';
import SalesIssue from '../components/staff/departments/sales/issues/SalesIssue';
import SalesFeature from '../components/staff/departments/sales/features/SalesFeature';
import WifiCallVerify from '../components/staff/departments/wificall/verify/WifiCallVerify';
import SalesRequisition from '../components/staff/departments/sales/requisitions/SalesRequisition';
import SalesEscalate from '../components/staff/departments/sales/escalate/SalesEscalate';
import SalesRelocation from '../components/staff/departments/sales/relocation/SalesRelocation';
import SalesReferral from '../components/staff/departments/sales/referral/SalesReferral';
import AccountsRequisition from '../components/staff/departments/accounts/requsitions/shared/AccountsRequisition';
import ProcurementRequisition from '../components/staff/departments/procurement/requisitions/ProcurementRequisition';
import NewProductsRequisition from '../components/staff/departments/new-products/requisitions/NewProductsRequisition';
import CustomerSupportRequisition from '../components/staff/departments/customer-support/requisitions/nigeria/CustomerSupportRequisition';
import NetworkOperationsRequisition from '../components/staff/departments/noc/reqiusition/NetworkOperationsRequisition';
import Requisition from '../components/staff/departments/research-and-development/requisitions/Requisition';
import WifiCallIssue from '../components/staff/departments/wificall/WifiCallIssue';
import WifiCallFeature from '../components/staff/departments/wificall/WifiCallFeature';
import LatencyGraph from '../components/staff/departments/noc/LatencyGraph';
import BandwidthGraph from '../components/staff/departments/noc/bandwidth-graph/BandwidthGraph';
import NetworkOperationsNoLos from '../components/staff/departments/noc/los/NetworkOperationsNoLos';
import ResearchScheduler from '../components/staff/departments/research-and-development/scheduler/Scheduler';
import CustomerSupportExpired from '../components/staff/departments/customer-support/CustomerSupportExpired';
import AccountsGeniatechCustomers from '../components/staff/departments/accounts/geniatech/AccountsGeniatechCustomers';
import IncidentReport from '../components/staff/departments/noc/incident-report/IncidentReport';
import NocSolarCount from '../components/staff/departments/noc/solar-count/NocSolarCount';
import NocPowerConsumption from '../components/staff/departments/noc/power-consumption/NocPowerConsumption';
import DedicatedCustomers from '../components/staff/departments/noc/dedicated-customers/DedicatedCustomers';
import RandDReportIssue from '../components/staff/departments/research-and-development/RandDReportIssue';
import RandDFeature from '../components/staff/departments/research-and-development/RandDFeature';
import CustomerSupportFeature from '../components/staff/departments/customer-support/features/CustomerSupportFeature';
import CustomerExtension from '../components/staff/departments/accounts/extension/CustomerExtension';
import ResearchMailBlast from '../components/staff/departments/research-and-development/mail-blast/ResearchMailBlast';
import NewProductsMailBlast from '../components/staff/departments/new-products/mail-blast/NewProductsMailBlast';
import ProcurementMailBlast from '../components/staff/departments/procurement/mail-blast/ProcurementMailBlast';
import SalesMailBlast from '../components/staff/departments/sales/mail-blast/SalesMailBlast';
import ResearchNetPromoterScore from '../components/staff/departments/research-and-development/net-promoter-score/ResearchNetPromoterScore';
import CustomerSupportPromoterScore from '../components/staff/departments/customer-support/net-promoter-score/CustomerSupportPromoterScore';
import NocPromoterScore from '../components/staff/departments/noc/net-promoter-score/NocPromoterScore';
import NetworkOperationsMailBlast from '../components/staff/departments/noc/mail-blast/NocMailBlast';
import AccountsMailBlast from '../components/staff/departments/accounts/mail-blast/AccountsMailBlast';
import VehicleTracking from '../components/staff/departments/research-and-development/vehicle-tracking/VehicleTracking';
import ResearchAndDevelopmentKpiMonitor from '../components/staff/departments/research-and-development/kpi-monitor/ResearchAndDevelopmentKpiMonitor';
import NewProductsKpiMonitor from '../components/staff/departments/new-products/kpi-monitor/NewProductKpiMonitor';
import AccountsKpiMonitor from '../components/staff/departments/accounts/kpi-monitor/AccountsKpiMonitor';
import ProcurementMonitor from '../components/staff/departments/procurement/kpi-monitor/ProcurementKpiMonitor';
import CustomerSupportMonitor from '../components/staff/departments/customer-support/kpi-monitor/CustomerSupportKpiMonitor';
import SalesKpiMonitor from '../components/staff/departments/sales/kpi-monitor/SalesKpiMonitor';
import HotspotAdminOverview from '../components/staff/departments/new-products/products/hotspot/overview/HotSpotAdminOverview';
import HotspotAdminEmpty from '../components/staff/departments/new-products/products/hotspot/layouts/PendingEmptyState';
import HotspotAdminTransaction from '../components/staff/departments/new-products/products/hotspot/transactions/HotSpotAdminTransactions';
import HotspotAdminFundWallet from '../components/staff/departments/new-products/products/hotspot/wallet/HotSpotAdminFundWallet';
import NewProductGeniatech from '../components/staff/departments/new-products/customers/NewProductGeniatech';
import ResearchRecruitment from '../components/staff/departments/research-and-development/recruitment/Recruitment';
import AccountsRecruitment from '../components/staff/departments/accounts/recruitment/Recruitment';
import SupportRecruitment from '../components/staff/departments/customer-support/recruitment/CustomerSupportRecruitment';
import NewProductRecruitment from '../components/staff/departments/new-products/recruitment/Recruitment';
import ProcurementRecruitment from '../components/staff/departments/procurement/recruitment/Recruitment';
import SalesRecruitment from '../components/staff/departments/sales/recruitment/Recruitment';
import AdminMailBlast from '../components/staff/departments/super-admin/mailblast/AdminMailBlast';
import StaticIp from '../components/staff/departments/noc/static-ip/StaticIp';
import { PrivateRoute } from '../components/staff/shared/auth/PrivateRoute';
import AllBasestations from '../components/staff/shared/basestations-new/AllBasestations';
import FibreLinks from '../components/staff/departments/noc/fibre-links/NocFibreLinks';
import PersonnelQualityAssurance from '../components/staff/departments/customer-support/quality-assurance/personnel/PersonnelQualityAssurance';
import AdminRetention from '../components/staff/departments/super-admin/customer-support/AdminRetention';
import AdminRequsitions from '../components/staff/departments/super-admin/requisitions/AdminRequsitions';
import FieldSupportVehicleTracking from '../components/staff/departments/field-support/vehicles/FieldSupportVehicleTracking';
import FieldSupportZones from '../components/staff/departments/field-support/zones/FieldSupportZones';
import FieldSupportEscalate from '../components/staff/departments/field-support/escalate/FieldSupportEscalate';
import ExpressWifiRequisition from '../components/staff/departments/express-wifi/requisitions/ExpressWifiRequisition';
import ExpressWifiEscalate from '../components/staff/departments/express-wifi/escalate/ExpressWifiEscalate';
import NocKpiMonitor from '../components/staff/departments/noc/kpi-monitor/NocKpiMonitor';
import NocCustomers from '../components/staff/departments/noc/customers/NocCustomers';
import NocSchedule from '../components/staff/departments/noc/schedule/NocSchedule';
import NocEscalate from '../components/staff/departments/noc/escalate/NocEscalate';
import ExpressWifiOverview from '../components/staff/departments/express-wifi/overview/ExpressWifiOverview';
import FieldSupportOverview from '../components/staff/departments/field-support/FieldSupportOverview';
import FieldSupportNoLos from '../components/staff/departments/field-support/los/FieldSupportNoLos';
import FieldSupportUsageHistory from '../components/staff/departments/field-support/usage-history/FieldSupportUsageHistory';
import FieldSupportBasestations from '../components/staff/departments/field-support/basestations/FieldSupportBasestations';
import FieldSupportCustomers from '../components/staff/departments/field-support/customers/FieldSupportCustomers';
import FieldSupportRecruitment from '../components/staff/departments/field-support/recruitment/FieldSupportRecruitment';
import FieldSupportIssue from '../components/staff/departments/field-support/issues/FieldSupportIssue';
import FieldSupportRetrievals from '../components/staff/departments/field-support/retrievals/FieldSupportRetrievals';
import FieldSupportNetPromoterScore from '../components/staff/departments/field-support/net-promoter-score/FieldSupportNetPromoterScore';
import FieldSupportMailBlast from '../components/staff/departments/field-support/mailBlast/FieldSupportMailBlast';
import FieldSupportKpiMonitor from '../components/staff/departments/field-support/kpi-monitor/FieldSupportKpiMonitor';
import FieldSupportFeature from '../components/staff/departments/field-support/features/FieldSupportFeature';
import NewProductsCustomerExtension from '../components/staff/departments/new-products/extension/NewProductsCustomerExtension';
import ProcurementCustomers from '../components/staff/departments/procurement/customers/ProcurementCustomers';
import SalesFailedInstallations from '../components/staff/departments/sales/installations/SalesFailedInstallations';
import WifiCallOverview from '../components/staff/departments/wificall/home/WifiCallOverview';
import NewDesignOverview from '../components/staff/new-design/overview/NewDesignOverview';
import AdminDepartmentOverview from '../components/staff/departments/admin-department/overview/AdminDepartmentOverview';
import AdministrationKpiMonitor from '../components/staff/departments/admin-department/kpi-monitor/AdminDepartmentKpiMonitor';
import Departments from '../components/staff/departments/admin-department/departments/Departments';
import AdministrationMailBlast from '../components/staff/departments/admin-department/mail-blast/AdministrationMailBlast';
import Shortlisted from '../components/staff/departments/admin-department/recruitment/Shortlisted';
import AllApplications from '../components/staff/departments/admin-department/recruitment/AllApplications';
import AdminDepartmentRequisition from '../components/staff/departments/admin-department/requisitions/AdminDepartmentRequisition';
// import AdminDepartmentEmployees from '../components/staff/departments/admin-department/employees/AdminDepartmentEmployees';
import AdminDepartmentVehicles from '../components/staff/departments/admin-department/vehicles/AdminDepartmentVehicles';
import AdminDeptInventories from '../components/staff/departments/admin-department/inventories/AdminDepartmentInventory';
import SalesInstallations from '../components/staff/departments/sales/installations/SalesInstallations';
import PendingRelocation from '../components/staff/departments/sales/relocation/pending-relocation/PendingRelocation';
import FieldSupportCrm from '../components/staff/departments/field-support/crm/FieldSupportCrm';
import PATHS from './urls';
import Routers from '../components/staff/departments/procurement/routers/Routers';
import Kpi from '../components/staff/departments/noc/kpi/Kpi';
import HotspotAdminRetailer from '../components/staff/departments/new-products/products/hotspot/retailer/HotSpotAdminRetailers';
import Auth from '../components/staff/shared/auth/presentational/Auth';
import SalesRequests from '../components/staff/departments/sales/admin-request/SalesRequests';
import AdministrationRequest from '../components/staff/departments/admin-department/requests/AdministrationRequest';
import AccountsRequests from '../components/staff/departments/accounts/requests/admin/AccountsRequests';
import AccountRequest from '../components/staff/departments/accounts/requests/non-admin/AccountRequest';
import CustomerSupportRequests from '../components/staff/departments/customer-support/requests/CustomerSupportRequests';
import ExpressWifiRequests from '../components/staff/departments/express-wifi/ExpressWifiRequests';
import FieldSupportRequests from '../components/staff/departments/field-support/requests/FieldSupportRequests';
import NocRequests from '../components/staff/departments/noc/requests/NocRequests';
import NewProductsRequests from '../components/staff/departments/new-products/requests/NewProductsRequests';
import ProcurementRequests from '../components/staff/departments/procurement/recruitment/ProcurementRequests';
import ResearchRequests from '../components/staff/departments/research-and-development/admin-requests/ResearchRequests';
import CustomerSupportArchives from '../components/staff/departments/customer-support/archives/CustomerSupportArchives';
import Count from '../components/staff/departments/wificall/Count';
import UnverifiedCustomers from '../components/staff/departments/wificall/customers/unverified/UnverifiedCustomers';
import ScheduleUser from '../components/staff/shared/installations/ScheduleUser';
import FieldSupportRequisitions from '../components/staff/departments/field-support/requisition/FieldSupportRequisitions';
import AdminDeptPayslip from '../components/staff/departments/admin-department/payslip/AdminDeptPayslip';
import NocPayslip from '../components/staff/departments/noc/payslip/NocPayslip';
import ConnectedCustomers from '../components/staff/shared/sales/ConnectedCustomers';
import RDPayslip from '../components/staff/departments/research-and-development/payslip/RDPayslip';
import VerifiedCustomers from '../components/staff/departments/wificall/customers/verified/VerifiedCustomers';
import WifiCallEscalate from '../components/staff/departments/wificall/escalate/WifiCallEscalate';
import ActiveCustomers from '../components/staff/departments/noc/lte/customers/active-customers/ActiveCustomers';
import NocProfiles from '../components/staff/departments/noc/lte/profiles/NocProfiles';
import NoclteAccounts from '../components/staff/departments/noc/lte/accounts/NOCLTEAccounts';
// import NocSubscribers from '../components/staff/departments/noc/lte/subscribers/NocSubscribers';
import NocExpiredSubscribers from '../components/staff/departments/noc/lte/subscribers/NocExpiredSubscribers';
import ExpiredCustomers from '../components/staff/departments/noc/lte/customers/expired-customers/ExpiredCustomers';
import AdminDepartmentEscalate from '../components/staff/departments/admin-department/escalate/AdminDepartmentEscalate';
import CustomerSupportPayslip from '../components/staff/departments/customer-support/payslip/CustomerSupportPayslip';
import ExpressWifiPayslip from '../components/staff/departments/express-wifi/payslip/ExpressWifiPayslip';
import FieldSupportPayslip from '../components/staff/departments/field-support/payslip/FieldSupportPayslip';
import NewProductsPayslip from '../components/staff/departments/new-products/payslip/NewProductsPayslip';
import SalesPayslip from '../components/staff/departments/sales/payslip/SalesPayslip';
import ExpressWifiRetailerSms from '../components/staff/departments/express-wifi/retailer/sms/ExpressWifiRetailerSms';
import RandDCustomers from '../components/staff/departments/research-and-development/customers/RandDCustomers';
import RDLearningPortal from '../components/staff/departments/research-and-development/learning-portal/RDLearningPortal';
import RDPurchaseOrder from '../components/staff/departments/research-and-development/purchase-order/RDPurchaseOrder';
import NocLearningPortal from '../components/staff/departments/noc/learning-portal/NocLearningPortal';
import NocPurchaseOrder from '../components/staff/departments/noc/purchase-order/NocPurchaseOrder';
import SalesLearningPortal from '../components/staff/departments/sales/learning-portal/SalesLearningPortal';
import SalesPurchaseOrder from '../components/staff/departments/sales/purchase-order/SalesPurchaseOrder';
import AccountsLearningPortal from '../components/staff/departments/accounts/learning-portal/AccountsLearningPortal';
import AccountsDedicatedInvoice from '../components/staff/departments/accounts/dedicated-invoice/AccountsDedicatedInvoice';
import AccountsDedicatedCustomers from '../components/staff/departments/accounts/dedicated-customers/DedicatedCustomers';
import AccountsCompletedInstallations from '../components/staff/departments/accounts/completed-installations/CompletedInstallations';
import AccountsRetrievals from '../components/staff/departments/accounts/retrievals/AccountsRetrievals';
import CustomerSupportLearningPortal from '../components/staff/departments/customer-support/learning-portal/CustomerSupportLearningPortal';
import CustomerSupportPurchaseOrder from '../components/staff/departments/customer-support/purchase-order/CustomerSupportPurchaseOrder';
import ExpressWifiLearningPortal from '../components/staff/departments/express-wifi/learning-portal/ExpressWifiLearningPortal';
import ExpressWifiActiveAmbassadors from '../components/staff/departments/express-wifi/active-ambassadors/ExpressWifiActiveAmbassadors';
import ExpressWifiAmbassadorsSignUps from '../components/staff/departments/express-wifi/ambassadors-signups/ExpressWifiAmbassadorsSignUps';
import ExpressWifiHotspotCustomers from '../components/staff/departments/express-wifi/hotspot-customers/ExpressWifiHotspotCustomers';
import ExpressWifiCustomersSignups from '../components/staff/departments/express-wifi/customer-signups/ExpressWifiCustomersSignups';
// import NewExpressPortal from '../components/staff/departments/express-wifi/new-express-portal/NewExpressPortal';
import WifiCallLearningPortal from '../components/staff/departments/wificall/learning-portal/WifiCallLearningPortal';
import FieldSupportLearningPortal from '../components/staff/departments/field-support/learning-portal/FieldSupportLearningPortal';
import FieldSupportPurchaseOrder from '../components/staff/departments/field-support/purchase-order/FieldSupportPurchaseOrder';
import ProcurementLearningPortal from '../components/staff/departments/procurement/learning-portal/ProcurementLearningPortal';
import ProcurementAccountReconciliation from '../components/staff/departments/procurement/account-reconciliation/ProcurementAccountReconciliation';
import AdminDeptLearningPortal from '../components/staff/departments/admin-department/learning-portal/AdminDeptLearningPortal';
import WifiCallActiveAndInactiveCustomers from '../components/staff/departments/wificall/customers/A2Billing/active-inactive/WifiCallActiveAndInactiveCustomers';
import WifiCallAssignedAndUnassigned from '../components/staff/departments/wificall/customers/A2Billing/assigned-unassigned/WifiCallAssignedAndUnassigned';
import WifiCallPayments from '../components/staff/departments/wificall/payments/WifiCallPayments';
import SendInvoices from '../components/staff/departments/wificall/send-invoices/SendInvoices';
import WifiCallLoginLogs from '../components/staff/departments/wificall/login-logs/WifiCallLoginLogs';
import WifiCallPurchaseOrder from '../components/staff/departments/wificall/purchase-order/WifiCallPurchaseOrder';
import ExpressWifiGeniatech from '../components/staff/departments/express-wifi/geniatech/ExpressWifiGeniatech';
import SalesLteKyc from '../components/staff/departments/sales/lte-kyc/SalesLteKyc';
import FieldSupportSchedule from '../components/staff/departments/field-support/scheduling/FieldSupportSchedule';
import InternetUnverifiedCustomers from '../components/staff/departments/customer-support/customers/unverified-customers/InternetUnverifiedCustomers';
import AllApprovedRequsitions from '../components/staff/departments/super-admin/requisitions/approved/AllApprovedRequsitions';
import AccountsApprovedRequisitions from '../components/staff/departments/accounts/requsitions/executives/approved/AccountsApprovedRequisitions';
import AccountsPaidRequisition from '../components/staff/departments/accounts/requsitions/executives/paid/AccountsPaidRequisition';
import AccountsNoLos from '../components/staff/departments/accounts/no-los/AccountsNoLos';
import SalesBudget from '../components/staff/departments/sales/budget/SalesBudget';
import RDBudget from '../components/staff/departments/research-and-development/budget/RDBudget';
import NewProductsBudget from '../components/staff/departments/new-products/budget/NewProductsBudget';
import NocBudget from '../components/staff/departments/noc/budget/NocBudget';
import FieldSupportBudget from '../components/staff/departments/field-support/budget/FieldSupportBudget';
import CustomerSupportBudget from '../components/staff/departments/customer-support/budget/CustomerSupportBudget';
import SalesDedicatedCustomers from '../components/staff/departments/sales/dedicated-customers/SalesDedicatedCustomers';
import MonthlyBasestationsUsers from '../components/staff/shared/basestation-data/monthly/MonthlyBasestationsUsers';
import CfoAdminRequests from '../components/staff/departments/accounts/cfo/admin/CfoAdminRequests';
import CfoExpressWifi from '../components/staff/departments/accounts/cfo/express-wifi/CfoExpressWifi';
import CFOTracklist from '../components/staff/departments/accounts/cfo/procurement/CFOTracklist';
import CFOReleaseReport from '../components/staff/departments/accounts/cfo/procurement/CFOReleaseReport';
import CFORetrievals from '../components/staff/departments/accounts/cfo/procurement/CFORetrievals';
import CFOAccountReconciliation from '../components/staff/departments/accounts/cfo/procurement/CFOAccountReconciliation';
import CFOInterDepartmentalPO from '../components/staff/departments/accounts/cfo/procurement/CFOInterDepartmentalPO';
import CallLogs from '../components/staff/departments/customer-support/call-logs/v2/CallLogs';
import ExpiredUsers from '../components/staff/departments/customer-support/expired-users/ExpiredUsers';
import AdminDeptPurchaseOrder from '../components/staff/departments/admin-department/purchase-order/AdminDeptPurchaseOrder';
import NocRetrievals from '../components/staff/departments/noc/retrievals/NocRetrievals';
import NetworkOperationBasestationStatus from '../components/staff/departments/noc/basestation-status/NetworkOperationBasestationStatus';
import AssetTeamClosedLeads from '../components/staff/departments/asset-team/closed-leads/AssetTeamClosedLeads';
import AssetTeamExpiredCustomers from '../components/staff/departments/asset-team/customers/expiredCustomer/AssetTeamExpiredCustomers';
import AssetTeamExpired from '../components/staff/departments/asset-team/customers/expired/AssetTeamExpired';
import AssetTeamActiveCustomersPerZone from '../components/staff/departments/asset-team/zones/active-zones/AssetTeamActiveCustomersPerZone';
import ExpiredCustomersByZones from '../components/staff/departments/asset-team/zones/expiredZones/ExpiredCustomersByZones';
import CustomerSupportActiveCustomers from '../components/staff/departments/customer-support/customers/active-customers/CustomerSupportActiveCustomers';
import CustomerSupportAssetTeamExpiredCustomers from '../components/staff/departments/customer-support/customers/expired/total/CustomerSupportAssetTeamExpiredCustomers';
import CustomerSupportAssetTeamSevenDaysExpired from '../components/staff/departments/customer-support/customers/expired/seven-days/CustomerSupportAssetTeamSevenDaysExpired';
import CustomerSupportExpiredCustomersByZones from '../components/staff/departments/customer-support/customers/expired-customers-by-zones/CustomerSupportExpiredCustomersByZones';
import Tickets from '../components/staff/departments/noc/tickets/Tickets';
import CreateCustomer from '../components/staff/departments/customer-support/customers/CreateCustomer';
import AssetTeamExpiredLteCustomers from '../components/staff/departments/asset-team/customers/lte/expired/AssetTeamExpiredLteCustomers';
import NewScheduleUser from '../components/staff/departments/sales/installations/schedule/NewScheduleUser';
import CustomerSupportActiveLteCustomers from '../components/staff/departments/customer-support/customers/lte/CustomerSupportActiveLteCustomers';
import AssetTeamActiveLteCustomers from '../components/staff/departments/asset-team/customers/lte/active/AssetTeamActiveLteCustomers';
import AssetLTEInstallation from '../components/staff/departments/asset-team/installations/lte/AssetTeamInstallationLteCustomers';
import FieldSupportSubZones from '../components/staff/departments/field-support/sub-zones/FieldSupportSubZones';
import SalesAssetTeamInstallations from '../components/staff/departments/sales/asset-team/SalesAssetTeamInstallations';
import GhanaRequisitions from '../components/staff/departments/customer-support/requisitions/ghana/GhanaRequisitions';
import FieldSupportGhanaRequisitions from '../components/staff/departments/field-support/requisition/ghana/FieldSupportGhanaRequisitions';
import FreeSignUps from '../components/staff/departments/wificall/free-setup-signups/FreeSignUps';
import ExpressWifiPurchaseOrder from '../components/staff/departments/express-wifi/purchase-order/ExpressWifiPurchaseOrder';
import AccessPointConnection from '../components/staff/departments/noc/sheets/access-points-connection/AccessPointConnection';
import CustomerSupportExpiredLteCustomers from '../components/staff/departments/customer-support/customers/lte/expired/CustomerSupportExpiredLteCustomers';
import HotspotServer from '../components/staff/departments/noc/sheets/hotspot-server/HotspotServer';
import EnbSiteIpAllocations from '../components/staff/departments/noc/sheets/enb-site-ip-allocation/EnbSiteIpAllocations';
import Ptp from '../components/staff/departments/noc/sheets/ptp/Ptp';
import FacebookSites from '../components/staff/departments/noc/sheets/facebook-sites/FacebookSites';
import RouterIpAddress from '../components/staff/departments/noc/sheets/router-ip-address/RouterIpAddress';
import SectorAllocation from '../components/staff/departments/noc/sheets/sector-allocation/SectorAllocation';
import SnmpCacti from '../components/staff/departments/noc/sheets/snmp-cacti/SnmpCacti';
import SheetDedicatedCustomer from '../components/staff/departments/noc/sheets/sheet-dedicated-customer/SheetDedicatedCustomer';
import BasestationConnection from '../components/staff/departments/noc/sheets/basestation-connection/BasestationConnection';
import FiberPptp from '../components/staff/departments/noc/sheets/fiber-pptp/FiberPptp';
import Towers from '../components/staff/departments/noc/cnmaestro/towers/Towers';
import Dummy from '../components/staff/shared/templates/with-context/dummy/Dummy';
import Alarms from '../components/staff/departments/noc/cnmaestro/alarms/Alarms';
import Statistics from '../components/staff/departments/noc/cnmaestro/statistics/Statistics';
import Devices from '../components/staff/departments/noc/cnmaestro/devices/Devices';
import DevicePerformances from '../components/staff/departments/noc/cnmaestro/performances/DevicePerformances';
import Sites from '../components/staff/departments/noc/cnmaestro/sites/Sites';
import CompletedSites from '../components/staff/departments/noc/completed-sites/CompletedSites';
import SubBasestation from '../components/staff/departments/noc/basestations/sub-basestation/SubBasestation';
import CustomerSupportLteInstallations from '../components/staff/departments/customer-support/installations/lte/CustomerSupportLteInstallations';
import Networks from '../components/staff/departments/noc/cnmaestro/networks/Networks';
import ListOfBasestations from '../components/staff/departments/noc/sheets/list-of-basestations/ListOfBasestations';
import PublicIpAllocations from '../components/staff/departments/noc/sheets/public-ip-allocation/PublicIpAllocations';
import IpAllocationPtp from '../components/staff/departments/noc/sheets/ip-allocation-ptp/IpAllocationPtp';
import IpAllocation from '../components/staff/departments/noc/sheets/ip-allocation/IpAllocation';
import FiberSitesPublicIp from '../components/staff/departments/noc/sheets/fiber-sites/fiber-sites-public-ip/FiberSitesPublicIp';
import StaticIpCustomers from '../components/staff/departments/noc/sheets/static-ip-customer/StaticIpCustomers';
import LteEnbIp from '../components/staff/departments/noc/sheets/lte-enb-ip/LteEnbIp';
import CpuUtilization from '../components/staff/departments/noc/sheets/fiber-sites/cpu-utilization/CpuUtilization';
import Snmp from '../components/staff/departments/noc/sheets/snmp/Snmp';
import PtpVlanIp from '../components/staff/departments/noc/sheets/ptp-vlan-ip/PtpVlanIp';
import AvailableInstallationTimes from '../components/staff/departments/field-support/available-times/AvailableInstallationTimes';
import FieldSupportInstallationsSummary from '../components/staff/departments/field-support/installations-summary/FieldSupportInstallationsSummary';
import FieldSupportEmployeesInstallations from '../components/staff/departments/field-support/employees-installation/EmployeesInstallations';
import UserAssignedInstallation from '../components/staff/departments/field-support/installations/personnel/assigned/UserAssignedInstallation';
import StaticAccessPoints from '../components/staff/departments/noc/cnmaestro/static-access-points/StaticAccessPoints';
import ViewOneRequisition from '../components/staff/shared/requisition/shared/one-requisition/ViewOneRequisition';
import Reboot from '../components/staff/departments/noc/cnmaestro/reboot/Reboot';
import CambiumLicenseKeys from '../components/staff/departments/noc/sheets/cambium-license-keys/CambiumLicenseKeys';
import AssetTeamExpiredCountry from '../components/staff/departments/asset-team/expired-country/AssetTeamExpiredCountry';
import AssetTeamInstalledCountry from '../components/staff/departments/asset-team/installed-country/AssetTeamInstalledCountry';
import OpenAndBlockedUsers from '../components/staff/departments/wificall/open-blocked-users/OpenAndBlockedUsers';
import AssetTeamActivePerCountry from '../components/staff/departments/asset-team/active-country/AssetTeamActivePerCountry';
import AssetTeamActiveAndInactive from '../components/staff/departments/asset-team/active-inactive/AssetTeamActiveAndInactive';
import AssetDeviceRetrieval from '../components/staff/departments/asset-team/device-retrieval/AssetDeviceRetrieval';
import CustomerSupportActiveAndInactiveCustomers from '../components/staff/departments/customer-support/active-inactive-customers/CustomerSupportActiveAndInactiveCustomers';
import CustomerSupportActiveAndInactiveCustomersRestricted from '../components/staff/departments/customer-support/active-inactive-customers/CustomerSupportActiveAndInactiveCustomersRestricted';

import CustomerSupportActiveCustomersPerCountry from '../components/staff/departments/customer-support/active-customers-per-country/CustomerSupportActiveCustomersPerCountry';
import CustomerSupportExpiredCustomersPerCountry from '../components/staff/departments/customer-support/expired-customers-per-country/CustomerSupportExpiredCustomersPerCountry';
import CustomerSupportInstallationsPerCountry from '../components/staff/departments/customer-support/installations-per-country/CustomerSupportInstallationsPerCountry';
import CustomerSupportDeviceRetrieval from '../components/staff/departments/customer-support/device-retrieval/CustomerSupportDeviceRetrieval';
import CustomerSupportMailBlast from '../components/staff/departments/customer-support/mail-blast/CustomerSupportMailBlast';
import AssetGraphs from '../components/staff/departments/asset-team/customers/AssetGraphs';
import CustomerSupportMessageLogs from '../components/staff/departments/customer-support/retention-logs/CustomerSupportRetentionLogs';
import PaystackTransactions from '../components/staff/departments/customer-support/paystack-transaction/paystack-transactions';
import AssetTeamCustomersTracker from '../components/staff/departments/asset-team/tracker/AssetTeamCustomersTracker';
import CustomerSupportTracker from '../components/staff/departments/customer-support/tracker/CustomerSupportTracker';
import AssetTeamActiveCustomers from '../components/staff/departments/asset-team/active-customer/AssetTeamActiveCustomers';
import AssetTeamInstallationsPerBasestation from '../components/staff/departments/asset-team/installations/AssetTeamInstallationsPerBasestation';
import CustomerSupportInstallationsPerBasestation from '../components/staff/departments/customer-support/installations-per-basestation/CustomerSupportInstallationsPerBasestation';
import SalesInstallationsPerBasestation from '../components/staff/departments/sales/installations-per-basestations/SalesInstallationsPerBasestation';
import InstallationOverview from '../components/staff/departments/field-support/installations/leads-and-manager/InstallationOverview';
import CustomerSupportInstallationOverview from '../components/staff/shared/feedback/send-feedback/CustomerSupportInstallationOverview';
import FieldSupportClustering from '../components/staff/departments/field-support/cluster-installation/FieldSupportClustering';
import CustomerSupportQualityAssurance from '../components/staff/departments/customer-support/quality-assurance/CustomerSupportQualityAssurance';
import ProcurementTracklistCategory from '../components/staff/departments/procurement/tracklist/tracklist-category/ProcurementTracklistCategory';
import FieldSupportRelocation from '../components/staff/departments/field-support/relocation/FieldSupportRelocation';
import AccountTracklistCategory from '../components/staff/departments/accounts/tracklist-category/AccountTracklistCategory';
import FieldSupportTeams from '../components/staff/departments/field-support/team/FieldSupportTeams';
import ProcurementCleanRadio from '../components/staff/departments/procurement/clean-radio/ProcurementCleanRadio';
import CustomerSupportLteCustomers from '../components/staff/departments/customer-support/lte-customers/CustomerSupportLteCustomers';
import CustomerSupportPaymentHistory from '../components/staff/departments/customer-support/customers/payment-history/CustomerSupportPaymentHistory';
import CustomerSupportDelistedCustomers from '../components/staff/departments/customer-support/delisted-customers/CustomerSupportDelistedCustomers';
import CustomerSupportCallReport from '../components/staff/departments/customer-support/call-report/CustomerSupportCallReport';
import CustomerSupportBulkCustomerDetails from '../components/staff/departments/customer-support/bulk-customer-details/CustomerSupportBulkCustomerDetails';
import SalesClusteredInstallations from '../components/staff/departments/sales/clustered-installs/SalesClusteredInstallations';
import FieldSupportClusteredInstalls from '../components/staff/departments/field-support/clustered-installs/FieldSupportClusteredInstalls';
import FieldSupportInstallationsCounter from '../components/staff/departments/field-support/installations-counter/FieldSupportInstallationsCounter';
import SalesRescheduledInstallations from '../components/staff/departments/sales/rescheduled-installations/SalesRescheduledInstallations';
import SuperAdminOverview from '../components/staff/departments/super-admin/overview/overview/SuperAdminOverview';
import SalesInstallationsCounterSummary from '../components/staff/departments/sales/installations-counter-summary/SalesInstallationsCounterSummary';
import SalesInstallationsPerDateRange from '../components/staff/departments/sales/installations-per-basestations/installations-date-range/SalesInstallationsPerDateRange';
import AssetTeamGeniatech from '../components/staff/departments/asset-team/geniatech/AssetTeamGeniatech';
import BusinessDevelopmentDedicatedCustomers from '../components/staff/departments/business-development/dedicated-customers/BusinessDevelopmentDedicatedCustomers';
import BusinessDevelopmentCallCard from '../components/staff/departments/business-development/call-card/BusinessDevelopmentCallCard';
import BusinessDevelopmentDelistedCustomers from '../components/staff/departments/business-development/dedicated-customers/BusinessDevelopmentDelistedCustomers';
import BusinessDevelopmentUsers from '../components/staff/departments/business-development/bizdev-users/BusinessDevelopmentUsers';
import BusinessDevelopmentDedicatedConnectedUsers from '../components/staff/departments/business-development/dedicated-connected-users/BusinessDevelopmentDedicatedConnectedUsers';
import BusinessDevelopmentRequisition from '../components/staff/departments/business-development/requisitions/BusinessDevelopmentRequisitions';
import BusinessDevelopmentBasestationStatus from '../components/staff/departments/business-development/basestation-status/BusinessDevelopmentBasestationStatus';
import BusinessDevelopmentCustomerRevenue from '../components/staff/departments/business-development/customer-revenue/BusinessDevelopmentCustomerRevenue';
import CustomerDetailsShared from '../components/staff/shared/customer-details/CustomerDetailsShared';
import FieldCustomerDetailsShared from '../components/staff/departments/field-support/customer-details/FieldCustomerDetailsShared';
import BusinessDevelopmentLogs from '../components/staff/departments/business-development/logs/BusinessDevelopmentLogs';
import ExpressWifiCustomers from '../components/staff/departments/express-wifi/customers/ExpressWifiCustomers';
import ExpressWifiRetailers from '../components/staff/departments/express-wifi/retailers/ExpressWifiRetailers';
import AssetTeamPayslip from '../components/staff/departments/asset-team/payslip/AssetTeamPayslip';
import BusinessDevelopmentPayslip from '../components/staff/departments/business-development/payslip/BusinessDevelopmentPayslip';
import StaffPayslip from '../components/staff/departments/accounts/payslip/staff-payslip/StaffPayslip';
import Experience from '../components/staff/departments/customer-support/Experience';
import CustomerSupportInstallationFeedback from '../components/staff/shared/installation-feedback/CustomerSupportInstallationFeedback';
import AssetTeamKpiMonitor from '../components/staff/departments/asset-team/kpi-monitor/AssetTeamKpiMonitor';
import BusinessDevelopmentKpiMonitor from '../components/staff/departments/business-development/BusinessDevelopmentKpiMonitor';
import ExpressWifiKpiMonitor from '../components/staff/departments/express-wifi/ExpressWifiKpiMonitor';
import ResetDepartment from '../components/staff/shared/auth/container/ResetDepartment';
import NewDesignInstallation from '../components/staff/new-design/installations/NewDesignInstallation';
import NewDesignInstallationZone from '../components/staff/new-design/installation-zones/NewDesignInstallationZone';
import NewDesignInstallationCounter from '../components/staff/new-design/installation-counter/NewDesignInstallationCounter';
import NewDesignInstallationAction from '../components/staff/new-design/installations/actions/NewDesignInstallationAction';
import NewDesignInstallationZoneAction from '../components/staff/new-design/installation-zones/actions/NewDesignInstallationZoneAction';
import RescheduledOverview from '../components/staff/shared/installations/reschedule/RescheduleOverview';
import FSERescheduleOverview from '../components/staff/shared/installations/reschedule/FSERescheduleOverview';
import FieldSupportCleanLTE from '../components/staff/departments/field-support/clean-imsi/FieldSupportCleanLTE';
import SalesBBNSignups from '../components/staff/departments/sales/bbn-signups/SalesBBNSignups';
import SalesJacket from '../components/staff/departments/sales/jackets/SalesJacket';
import AccountsLte from '../components/staff/departments/accounts/lte/AccountsLte';
import AccountsAddLte from '../components/staff/departments/accounts/add-lte/AccountsAddLte';
// import KpiDashboard from '../components/layouts/CooDashboard/StatisticsDashboard';
import StatisticsOverview from '../components/staff/departments/super-admin/statistics-dashboard/StatisticsOverview';
import SalesWifiEvent from '../components/staff/departments/sales/wifi-event/SalesWifiEvent';
import AdminEmployee from '../components/staff/shared/employees/AdminEmployee';
import Tracklist from '../components/staff/shared/tracklist/Tracklist';
import ProcurementDedicatedCustomers from '../components/staff/departments/procurement/dedicated-customers/ProcurementDedicatedCustomers';
import PartnershipOverview from '../components/staff/departments/partnership/PartnershipOverview';
import SalesLogHeader from '../components/staff/departments/sales/logs/SalesLogHeader';
import PartnershipLogs from '../components/staff/departments/partnership/logs/PartnershipLogs';
import RefundFlow from '../components/staff/shared/refunds/RefundFlow';
import CustomerSupportDedicatedCustomer from '../components/staff/departments/customer-support/procurement-dedicated-customer/CustomerSupportDedicatedCustomer';
import SalesUsageHistory from '../components/staff/departments/sales/usage-history/SalesUsageHistory';
import BusinessDevelopmentUsageHistory from '../components/staff/departments/business-development/usage-history/BusinessDevelopmentUsageHistory';
import AccountsInstallationsCounter from '../components/staff/departments/accounts/installations-counter/AccountsInstallationsCounter';
import CustomisationInstallationReport from '../components/staff/departments/customer-support/installation-report/CustomerInstallationReport';
import SupportCalls from '../components/staff/departments/customer-support/support-calls/SupportCalls';
import RelocationShared from '../components/staff/shared/RelocationShared/RelocationShared';
import DowntimeLogger from '../components/staff/departments/noc/downtime-logger/DowntimeLogger.Index';
import SupportCallsCount from '../components/staff/departments/customer-support/support-calls/SupportCallsCount';
import AssetSupportCalls from '../components/staff/departments/asset-team/support-calls/AssetSupportCalls';
import ProductUptime from '../components/staff/shared/product-uptime/Productuptime';
import FieldSupportActiveAndInactiveCustomers from '../components/staff/departments/field-support/active-inactive-customers/FieldSupportActiveInactiveCustomers';
import UsageHistory from '../components/staff/departments/partnership/usage-history/UsageHistory';
import SignupHistory from '../components/staff/departments/partnership/signup-history/SignupHistory';
import PartnershipActiveAndInactiveCustomers from '../components/staff/departments/partnership/active-inactive-customers/PartnershipActiveAndInactiveCustomers';
import EmployeeLeaveDaysOverview from '../components/staff/departments/admin-department/employee-leave-module/EmployeeLeaveDaysOverview';
import VPSalesOverview from '../components/staff/departments/vp-sales/VPSalesOverview';
// import NOCSubscribers from '../components/staff/departments/noc/lte/subscribers/NocSubscribers';
import DeviceLoggerOverview from '../components/staff/departments/noc/devices-data-logger/DeviceLoggerOverview';
import TCOverview from '../components/staff/departments/operations/terms-conditions/TCOverview';
import CustomerSupportCallLogs from '../components/staff/departments/customer-support/call-log/CustomerSupportCallLogs';
import PartnershipBandwidth from '../components/staff/departments/partnership/bandwidth/PartnershipBandwidth';
import PartnershipBandwidthSchedule from '../components/staff/departments/partnership/bandwidth/PartnershipBandwidthSchedule';
import CallLogAnalytics from '../components/staff/departments/customer-support/call-log/CallLogAnalytics';
import RefundStatisticsOverview from '../components/staff/departments/super-admin/refund-analytics/RefundAnalytics';
import DeviceTypes from '../components/staff/departments/partnership/device-type/DeviceTypes';
import AssetTeamDedicatedCustomers from '../components/staff/departments/asset-team/dedicated-customers/AssetTeamDedicatedCustomers';
import FieldSupportCustomerView from '../components/staff/departments/field-support/customers/FieldSupportCustomerView';
import IncidentReportNew from '../components/staff/departments/noc/incident-report-new/IncidentReportNew';
import CustomerDowntimeOverview from '../components/staff/departments/field-support/customer-downtime/CustomerDowntimeOverview';

const Routes = () => (
  <App>
    <Switch>
      {/* department routes in alphabetical order */}
      {/* auth routes */}
      <Route exact path={PATHS.ADMIN_HOME_URL} component={Auth} />
      <Route exact path={PATHS.LOGIN_URL} component={Auth} />
      <Route exact path={PATHS.FORGOT_PASSWORD_URL} component={ForgotPassword} />
      <Route exact path="/reset/:token" component={ResetPassword} />
      <PrivateRoute exact path="/resetdepartment" component={ResetDepartment} />
      {/* auth ends */}

      {/* Account starts */}
      <PrivateRoute exact path="/accounts" component={AccountsOverview} />
      <PrivateRoute exact path="/accounts/employees" component={AccountEmployees} />
      <PrivateRoute exact path="/accounts/purchase-order" component={AccountsPurchaseOrder} />
      <PrivateRoute exact path="/accounts/cfo/purchase-order" component={CFOProcurementPurchaseOrder} />
      <PrivateRoute exact path="/accounts/departments-purchase-order" component={DepartmentsPurchaseOrder} />
      <PrivateRoute exact path="/accounts/approved-purchase-order" component={ApprovedPurchaseOrder} />
      <PrivateRoute exact path="/accounts/inventories" component={AccountInventories} />
      <PrivateRoute exact path="/accounts/vehicles" component={AccountsVehicles} />
      <PrivateRoute exact path="/accounts/lte" component={AccountsLte} />
      <PrivateRoute exact path="/accounts/add-lte" component={AccountsAddLte} />
      <PrivateRoute exact path="/accounts/basestation" component={AccountsBaseStations} />
      <PrivateRoute exact path="/shared/all-basestations" component={AllBasestations} />
      <PrivateRoute exact path="/accounts/report-issue" component={ReportIssue} />
      <PrivateRoute exact path="/accounts/request-feature" component={RequestFeature} />
      <PrivateRoute exact path={PATHS.ACCOUNTS_REQUISITIONS} component={AccountsRequisition} />
      <PrivateRoute exact path={PATHS.ACCOUNTS_STAFF_PAYSLIPS} component={StaffPayslip} />
      <PrivateRoute exact path="/accounts/geniatech" component={AccountsGeniatechCustomers} />
      <PrivateRoute exact path="/accounts/customers-extensions" component={CustomerExtension} />
      <PrivateRoute exact path="/accounts/mail-blast" component={AccountsMailBlast} />
      <PrivateRoute exact path="/accounts/kpi-monitor" component={AccountsKpiMonitor} />
      <PrivateRoute exact path={PATHS.ACCOUNTS_RECRUITMENT} component={AccountsRecruitment} />
      <PrivateRoute exact path="/accounts/requests" component={AccountsRequests} />
      <PrivateRoute exact path="/accounts/admin-requests" component={AccountRequest} />
      <PrivateRoute exact path="/accounts/escalate" component={AccountsEscalate} />
      <PrivateRoute exact path="/accounts/learning-portal" component={AccountsLearningPortal} />
      <PrivateRoute exact path="/accounts/dedicated-invoice" component={AccountsDedicatedInvoice} />
      <PrivateRoute exact path="/accounts/dedicated-customers" component={AccountsDedicatedCustomers} />
      <PrivateRoute exact path="/accounts/completed-installations" component={AccountsCompletedInstallations} />
      <PrivateRoute exact path="/accounts/firs-transactions" component={FirsTransactions} />
      <PrivateRoute exact path="/accounts/firs-reverse-transactions" component={FirsReverseTransactions} />
      <PrivateRoute exact path="/accounts/firs-reversed-transactions" component={FirsReversedTransactions} />
      <PrivateRoute exact path="/accounts/retrievals" component={AccountsRetrievals} />
      <PrivateRoute exact path={PATHS.ACCOUNTS_PAID_REQUISITION} component={AccountsPaidRequisition} />
      <PrivateRoute exact path={PATHS.ACCOUNTS_ALL_APPROVED_REQUISITIONS} component={AccountsApprovedRequisitions} />
      <PrivateRoute exact path={PATHS.ACCOUNTS_NO_LOS} component={AccountsNoLos} />
      <PrivateRoute exact path={PATHS.CFO_EXPRESS_WIFI_RETAILER_SMS} component={CfoExpressWifi} />
      <PrivateRoute exact path="/accounts/cfo/admin-requests" component={CfoAdminRequests} />
      <PrivateRoute exact path="/accounts/cfo/tracklist" component={CFOTracklist} />
      <PrivateRoute exact path="/accounts/cfo/release-report" component={CFOReleaseReport} />
      <PrivateRoute exact path="/accounts/cfo/retrievals" component={CFORetrievals} />
      <PrivateRoute exact path="/accounts/cfo/account-reconciliation" component={CFOAccountReconciliation} />
      <PrivateRoute exact path="/accounts/cfo/interdepartmental-purchase-order" component={CFOInterDepartmentalPO} />
      <PrivateRoute exact path={PATHS.ACCOUNTS_TRACKLIST_CATEGORY} component={AccountTracklistCategory} />
      <PrivateRoute exact path="/accounts/installations-counter" component={AccountsInstallationsCounter} />
      <PrivateRoute exact path={PATHS.ACCOUNTS_REFUNDS_ANALYTICS} component={RefundStatisticsOverview} />

      {/* Account ends */}

      {/* super-admin screens start */}
      {/* <PrivateRoute exact path="/super-admin" component={Overview} /> */}
      <PrivateRoute exact path="/super-admin" component={SuperAdminOverview} />
      {/* <PrivateRoute exact path={PATHS.SUPER_ADMIN_EMPLOYEES} component={Employees} />
      <PrivateRoute exact path="/super-admin/basestations" component={BaseStations} /> */}
      <PrivateRoute exact path={PATHS.SUPER_ADMIN_RETENTION} component={AdminRetention} />
      {/* <PrivateRoute exact path={PATHS.SUPER_ADMIN_INSTALLATIONS} component={Installations} />
      <PrivateRoute exact path="/super-admin/usage-history" component={UsageHistory} />
      <PrivateRoute exact path="/super-admin/static-ip" component={StaticIp} /> */}
      <PrivateRoute exact path={PATHS.SUPER_ADMIN_REQUISITIONS} component={AdminRequsitions} />
      <PrivateRoute exact path={PATHS.SUPER_ADMIN_ALL_APPROVED_REQUISITIONS} component={AllApprovedRequsitions} />

      <PrivateRoute exact path="/coo/kpi-dashboard" component={StatisticsOverview} />
      <PrivateRoute exact path="/refunds-analytics" component={RefundStatisticsOverview} />

      {/* <PrivateRoute exact path="/schedule" component={Admin} />
      <PrivateRoute exact path="/super-admin/vehicles" component={Vehicles} />
      <PrivateRoute exact path="/super-admin/kpi-monitor" component={AdminKpiMonitor} /> */}
      <PrivateRoute exact path={PATHS.SUPER_ADMIN_MAILBLAST} component={AdminMailBlast} />
      {/* <PrivateRoute exact path={PATHS.SUPER_ADMIN_RECRUITMENT} component={SuperAdminRecruitment} /> */}
      {/* super-admin screens end */}

      {/* vice-president starts */}
      <PrivateRoute exact path="/vp-sales" component={VPSalesOverview} />

      {/* vice-president sales end */}
      {/* Admin department start */}
      <PrivateRoute exact path="/administration" component={AdminDepartmentOverview} />
      <PrivateRoute exact path="/administration/inventories" component={AdminDeptInventories} />
      <PrivateRoute exact path="/administration/vehicles" component={AdminDepartmentVehicles} />
      <PrivateRoute exact path="/shared/employees" component={AdminEmployee} />
      <PrivateRoute exact path="/administration/payslip" component={AdminDeptPayslip} />
      <PrivateRoute exact path="/administration/requisitions" component={AdminDepartmentRequisition} />
      <PrivateRoute exact path="/administration/kpi-monitor" component={AdministrationKpiMonitor} />
      <PrivateRoute exact path="/departments" component={Departments} />
      <PrivateRoute exact path="/administration/all-applications" component={AllApplications} />
      <PrivateRoute exact path="/administration/shortlisted" component={Shortlisted} />
      <PrivateRoute exact path="/administration/mail-blast" component={AdministrationMailBlast} />
      <PrivateRoute exact path="/administration/requests" component={AdministrationRequest} />
      <PrivateRoute exact path="/administration/escalate" component={AdminDepartmentEscalate} />
      <PrivateRoute exact path="/administration/learning-portal" component={AdminDeptLearningPortal} />
      <PrivateRoute exact path="/administration/purchase-order" component={AdminDeptPurchaseOrder} />
      <PrivateRoute exact path="/administration/leave-days" component={EmployeeLeaveDaysOverview} />
      {/* Admin department end */}

      {/* customersupport start */}
      <PrivateRoute exact path="/customer-support" component={CustomerSupportOverview} />
      <PrivateRoute exact path="/customer-support/customers" component={CustomerSupportCustomers} />
      <PrivateRoute exact path="/customer-support/archives" component={CustomerSupportArchives} />
      <PrivateRoute exact path="/shared/installations" component={CustomerSupportInstallationOverview} />
      <PrivateRoute exact path="/retention" component={CustomerRetention} />
      <PrivateRoute exact path="/customer-support/active-zones" component={CustomerSupportActiveCustomersPerZone} />
      <PrivateRoute exact path="/customer-support/usage-history" component={CustomerSupportUsageHistory} />
      <PrivateRoute exact path="/customer-support/usage-statistics" component={CustomerSupportLowDataUsage} />
      <PrivateRoute exact path={PATHS.CUSTOMER_SUPPORT_UNVERIFIED_CUSTOMERS} component={InternetUnverifiedCustomers} />
      {/* <PrivateRoute exact path="/customer-support/transactions" component={CustomerSupportTransactions} /> */}
      <PrivateRoute exact path="/customer-support/relocation" component={RelocationShared} />
      <PrivateRoute exact path="/customer-support/call-logs" component={CustomerSupportCallLogs} />
      <PrivateRoute exact path="/shared/relocation/pending-relocation" component={PendingRelocation} />
      <PrivateRoute exact path="/customer-support/basestations" component={CustomerSupportBaseStations} />
      <PrivateRoute exact path="/customer-support/basestation-users" component={CustomerSupportBaseStationData} />
      <PrivateRoute exact path="/customer-support/basestation-users-month" component={MonthlyBasestationsUsers} />
      <PrivateRoute exact path="/customer-support/call-log" component={CustomerSupportCallog} />
      <PrivateRoute exact path="/customer-support/expired-users" component={ExpiredUsers} />
      <PrivateRoute exact path="/customer-support/schedule" component={CustomerSupportSchedule} />
      <PrivateRoute exact path="/customer-support/escalate" component={CustomerSupportEscalate} />
      <PrivateRoute exact path="/customer-support/report-issue" component={CustomerSupportReportIssue} />
      <PrivateRoute exact path="/customer-support/request-feature" component={CustomerSupportFeature} />
      <PrivateRoute exact path="/customer-support/requisition" component={CustomerSupportRequisition} />
      <PrivateRoute exact path="/past-expired" component={CustomerSupportExpired} />
      <PrivateRoute exact path="/customer-support/mail-blast" component={CustomerSupportMailBlast} />
      <PrivateRoute exact path="/customer-support/promoter-score" component={CustomerSupportPromoterScore} />
      <PrivateRoute exact path={PATHS.CUSTOMER_SUPPORT_RECRUITMENT} component={SupportRecruitment} />
      <PrivateRoute exact path="/customer-support/kpi-monitor" component={CustomerSupportMonitor} />
      <PrivateRoute exact path="/customer-support/quality-assurance" component={CustomerSupportQualityAssurance} />
      <PrivateRoute exact path="/customer-support/personnel-quality-assurance" component={PersonnelQualityAssurance} />
      <PrivateRoute exact path="/customer-support/admin-requests" component={CustomerSupportRequests} />
      <PrivateRoute exact path="/customer-support/admin-requests" component={CustomerSupportRequests} />
      <PrivateRoute exact path="/customer-support/learning-portal" component={CustomerSupportLearningPortal} />
      <PrivateRoute exact path="/customer-support/purchase-order" component={CustomerSupportPurchaseOrder} />
      <PrivateRoute exact path={PATHS.CUSTOMER_SUPPORT_PAYSLIP} component={CustomerSupportPayslip} />
      <PrivateRoute exact path={PATHS.CUSTOMER_SUPPORT_BUDGET} component={CustomerSupportBudget} />
      <PrivateRoute exact path={PATHS.CUSTOMER_SUPPORT_CALL_LOGS} component={CallLogs} />
      <PrivateRoute exact path="/customer-support/leads" component={CustomerSupportLeads} />
      <PrivateRoute exact path="/shared/qa-installations" component={CustomisationInstallationReport} />
      <PrivateRoute exact path={PATHS.CUSTOMER_SUPPORT_ACTIVE_CUSTOMERS} component={CustomerSupportActiveCustomers} />
      <PrivateRoute
        exact
        path={PATHS.CUSTOMER_SUPPORT_ASSET_EXPIRED}
        component={CustomerSupportAssetTeamExpiredCustomers}
      />
      <PrivateRoute
        exact
        path={PATHS.CUSTOMER_SUPPORT_ASSET_SEVEN_DAYS_EXPIRED}
        component={CustomerSupportAssetTeamSevenDaysExpired}
      />
      <PrivateRoute
        exact
        path={PATHS.CUSTOMER_SUPPORT_ASSET_EXPIRED_ZONES}
        component={CustomerSupportExpiredCustomersByZones}
      />
      <PrivateRoute exact path={PATHS.CUSTOMER_SUPPORT_PAYMENT_HISTORY} component={CustomerSupportPaymentHistory} />
      <PrivateRoute
        exact
        path={PATHS.CUSTOMER_SUPPORT_ASSET_TEAM_INSTALLATIONS}
        component={CustomerSupportInstallationsPerBasestation}
      />
      <PrivateRoute exact path={PATHS.CUSTOMER_SUPPORT_CREATE_CUSTOMER} component={CreateCustomer} />
      <PrivateRoute
        exact
        path={PATHS.CUSTOMER_SUPPORT_ACTIVE_LTE_CUSTOMERS}
        component={CustomerSupportActiveLteCustomers}
      />
      <PrivateRoute exact path={PATHS.CUSTOMER_SUPPORT_LTE_CUSTOMERS} component={CustomerSupportLteCustomers} />
      <PrivateRoute exact path={PATHS.CUSTOMER_SUPPORT_GHANA_CUSTOMER_REQUISITIONS} component={GhanaRequisitions} />
      <PrivateRoute
        exact
        path={PATHS.CUSTOMER_SUPPORT_EXPIRED_LTE_CUSTOMERS}
        component={CustomerSupportExpiredLteCustomers}
      />
      <PrivateRoute exact path={PATHS.CUSTOMER_SUPPORT_LTE_INSTALLATIONS} component={CustomerSupportLteInstallations} />
      <PrivateRoute
        exact
        path={PATHS.CUSTOMER_SUPPORT_ACTIVE_AND_INACTIVE_CUSTOMERS}
        component={CustomerSupportActiveAndInactiveCustomers}
      />
      <PrivateRoute
        exact
        path={PATHS.CUSTOMER_SUPPORT_ACTIVE_AND_INACTIVE_CUSTOMERS_RESTRICTED}
        component={CustomerSupportActiveAndInactiveCustomersRestricted}
      />
      <PrivateRoute
        exact
        path={PATHS.CUSTOMER_SUPPORT_ACTIVE_CUSTOMERS_PER_COUNTRY}
        component={CustomerSupportActiveCustomersPerCountry}
      />
      <PrivateRoute
        exact
        path={PATHS.CUSTOMER_SUPPORT_EXPIRED_CUSTOMERS_PER_COUNTRY}
        component={CustomerSupportExpiredCustomersPerCountry}
      />
      <PrivateRoute
        exact
        path={PATHS.CUSTOMER_SUPPORT_INSTALLATIONS_PER_COUNTRY}
        component={CustomerSupportInstallationsPerCountry}
      />
      <PrivateRoute exact path={PATHS.CUSTOMER_SUPPORT_DEVICE_RETRIEVAL} component={CustomerSupportDeviceRetrieval} />
      <PrivateRoute exact path={PATHS.CUSTOMER_SUPPORT_RETENTION_LOGS} component={CustomerSupportMessageLogs} />
      <PrivateRoute exact path={PATHS.CUSTOMER_SUPPORT_FAILED_PAYSTACK_TRANSACTIONS} component={PaystackTransactions} />
      <PrivateRoute
        exact
        path={PATHS.CUSTOMER_SUPPORT_INSTALLATION_FEEDBACK}
        component={CustomerSupportInstallationFeedback}
      />
      <PrivateRoute exact path={PATHS.CUSTOMER_SUPPORT_TRACKER} component={CustomerSupportTracker} />
      <PrivateRoute
        exact
        path={PATHS.CUSTOMER_SUPPORT_DELISTED_CUSTOMERS}
        component={CustomerSupportDelistedCustomers}
      />
      <PrivateRoute exact path={PATHS.CUSTOMER_SUPPORT_CALL_REPORT} component={CustomerSupportCallReport} />
      <PrivateRoute
        exact
        path={PATHS.CUSTOMER_SUPPORT_BULK_CUSTOMER_DETAILS}
        component={CustomerSupportBulkCustomerDetails}
      />
      <Route exact path="/support-calls" component={SupportCalls} />
      <Route exact path="/call-log-analytics" component={CallLogAnalytics} />
      <Route exact path="/support-calls-count" component={SupportCallsCount} />
      <Route exact path="/asset-team/support-calls" component={AssetSupportCalls} />
      <Route exact path="/experience" component={Experience} />
      <PrivateRoute exact path="/customer-support/dedicated-customers" component={CustomerSupportDedicatedCustomer} />
      {/* customersupport ends */}

      {/* ExpressWifi start */}
      <PrivateRoute exact path={PATHS.EXPRESS_WIFI_KPI_MONITOR} component={ExpressWifiKpiMonitor} />
      <PrivateRoute exact path="/express-wifi" component={ExpressWifiOverview} />
      <PrivateRoute exact path="/express-wifi/requisitions" component={ExpressWifiRequisition} />
      <PrivateRoute exact path="/express-wifi/ambassadors" component={ExpressWifiActiveAmbassadors} />
      <PrivateRoute exact path="/express-wifi/ambassadors-signups" component={ExpressWifiAmbassadorsSignUps} />
      <PrivateRoute exact path="/express-wifi/hotspot-customers" component={ExpressWifiHotspotCustomers} />
      <PrivateRoute exact path="/express-wifi/customers-signups" component={ExpressWifiCustomersSignups} />
      <PrivateRoute exact path="/express-wifi/admin-requests" component={ExpressWifiRequests} />
      <PrivateRoute exact path="/express-wifi/escalate" component={ExpressWifiEscalate} />
      <PrivateRoute exact path="/express-wifi/learning-portal" component={ExpressWifiLearningPortal} />
      <PrivateRoute exact path="/express-wifi/purchase-order" component={ExpressWifiPurchaseOrder} />
      {/* <PrivateRoute exact path="/express-wifi/new-express-portal" component={NewExpressPortal} /> */}
      <PrivateRoute exact path={PATHS.EXPRESS_WIFI_PAYSLIP} component={ExpressWifiPayslip} />
      <PrivateRoute exact path={PATHS.EXPRESS_WIFI_RETAILER_SMS} component={ExpressWifiRetailerSms} />
      <PrivateRoute exact path={PATHS.EXPRESS_WIFI_GENIATECH} component={ExpressWifiGeniatech} />
      <PrivateRoute exact path={PATHS.EXPRESS_WIFI_CUSTOMERS} component={ExpressWifiCustomers} />
      <PrivateRoute exact path={PATHS.EXPRESS_WIFI_RETAILERS} component={ExpressWifiRetailers} />

      {/* field support starts */}
      <PrivateRoute exact path="/field-support" component={FieldSupportOverview} />
      <PrivateRoute exact path="/field-support/customers" component={FieldSupportCustomerView} />

      <PrivateRoute exact path="/field-support/basestations" component={FieldSupportBasestations} />
      {/* <PrivateRoute exact path="/field-support/installations" component={FieldSupportInstallations} /> */}

      <PrivateRoute exact path="/installation-zones" component={FieldSupportZones} />
      <PrivateRoute exact path="/field-support/usage-history" component={FieldSupportUsageHistory} />
      <PrivateRoute exact path="/no-los" component={FieldSupportNoLos} />
      <PrivateRoute exact path="/field-support/admin-requests" component={FieldSupportRequests} />
      <PrivateRoute exact path="/teams" component={FieldSupportTeams} />
      <PrivateRoute exact path="/field-support/mail-blast" component={FieldSupportMailBlast} />
      <PrivateRoute exact path="/field-support/promoter-score" component={FieldSupportNetPromoterScore} />
      <PrivateRoute exact path="/field-support/issue" component={FieldSupportIssue} />
      <PrivateRoute exact path={PATHS.FIELD_SUPPORT_DEVICE_RETRIEVAL} component={FieldSupportRetrievals} />
      <PrivateRoute exact path="/field-support/request-feature" component={FieldSupportFeature} />
      <PrivateRoute exact path={PATHS.FIELD_SUPPORT_REQUISITIONS} component={FieldSupportRequisitions} />
      <PrivateRoute exact path={PATHS.FIELD_SUPPORT_GHANA_REQUISITIONS} component={FieldSupportGhanaRequisitions} />
      <PrivateRoute exact path="/field-support/kpi-monitor" component={FieldSupportKpiMonitor} />
      <PrivateRoute exact path={PATHS.FIELD_SUPPORT_SUPPORT_RECRUITMENT} component={FieldSupportRecruitment} />
      <PrivateRoute exact path="/field-support/crm" component={FieldSupportCrm} />
      <PrivateRoute exact path="/field-support/escalate" component={FieldSupportEscalate} />
      <PrivateRoute exact path="/field-support/learning-portal" component={FieldSupportLearningPortal} />
      <PrivateRoute exact path={PATHS.FIELD_SUPPORT_PAYSLIP} component={FieldSupportPayslip} />
      <PrivateRoute exact path={PATHS.FIELD_SUPPORT_BUDGET} component={FieldSupportBudget} />
      <PrivateRoute exact path="/field-support/purchase-order" component={FieldSupportPurchaseOrder} />
      <PrivateRoute exact path="/field-support/vehicle-tracking" component={FieldSupportVehicleTracking} />
      <PrivateRoute exact path="/field-support/clustering" component={FieldSupportClustering} />
      <PrivateRoute exact path={PATHS.FIELD_SUPPORT_SCHEDULING} component={FieldSupportSchedule} />
      <PrivateRoute exact path={PATHS.FIELD_SUPPORT_RELOCATION} component={FieldSupportRelocation} />
      <PrivateRoute exact path={PATHS.FSE_CLUSTERED_INSTALLATIONS} component={FieldSupportClusteredInstalls} />
      <PrivateRoute
        exact
        path={PATHS.FIELD_SUPPORT_INSTALLATIONS_SUMMARY}
        component={FieldSupportInstallationsSummary}
      />
      <PrivateRoute
        exact
        path={PATHS.FIELD_SUPPORT_EMPLOYEES_INSTALLATIONS}
        component={FieldSupportEmployeesInstallations}
      />
      <PrivateRoute
        exact
        path={PATHS.FIELD_SUPPORT_ACTIVE_AND_INACTIVE_CUSTOMERS}
        component={FieldSupportActiveAndInactiveCustomers}
      />
      <PrivateRoute exact path="/field-support/customer-details" component={FieldCustomerDetailsShared} />
      <PrivateRoute exact path="/field-support/clean-lte" component={FieldSupportCleanLTE} />
      <PrivateRoute exact path="/field-support/terms-and-conditions" component={TCOverview} />
      <PrivateRoute
        exact
        path={PATHS.FIELD_SUPPORT_INSTALLATIONS_COUNTER}
        component={FieldSupportInstallationsCounter}
      />
      <PrivateRoute exact path={PATHS.FIELD_SUPPORT_INSTALLATION_SUB_ZONES} component={FieldSupportSubZones} />
      <PrivateRoute
        exact
        path={PATHS.FIELD_SUPPORT_AVAILABLE_INSTALLATION_TIMES}
        component={AvailableInstallationTimes}
      />
      <PrivateRoute exact path={PATHS.FIELD_SUPPORT_INSTALLATION_OVERVIEW} component={InstallationOverview} />
      <PrivateRoute exact path="/shared/installations/reschedule" component={RescheduledOverview} />
      <PrivateRoute exact path="/installations/reschedule" component={FSERescheduleOverview} />
      <PrivateRoute exact path={PATHS.FIELD_SUPPORT_USER_ASSIGNED_INSTALLATION} component={UserAssignedInstallation} />

      {/* field support ends */}

      {/* Network Operations start */}
      <PrivateRoute exact path="/network-operations" component={NetworkOperationsOverview} />
      <PrivateRoute exact path="/network-operations/basestations" component={NetworkOperationsBaseStation} />
      <PrivateRoute exact path="/network-operations/basestation-status" component={NetworkOperationBasestationStatus} />
      <PrivateRoute exact path="/shared/basestations" component={AllBasestations} />
      <PrivateRoute exact path="/network-operations/access-points" component={NetworkOperationsAccessPoints} />
      <PrivateRoute exact path="/network-operations/frequency-log" component={NetworkOperationsFrequencyLog} />
      <PrivateRoute exact path="/network-operations/public-ip" component={NetworkPublicIP} />
      <PrivateRoute exact path="/network-operations/issue" component={NetworkOperationsIssue} />
      <PrivateRoute exact path="/downtime-logger" component={DowntimeLogger} />
      <PrivateRoute exact path={PATHS.NOC_LTE_ACCOUNTS} component={NoclteAccounts} />
      <PrivateRoute exact path={PATHS.NOC_LTE_ACTIVE_CUSTOMERS} component={ActiveCustomers} />
      <PrivateRoute exact path={PATHS.NOC_LTE_EXPIRED_CUSTOMERS} component={ExpiredCustomers} />
      <PrivateRoute exact path={PATHS.NOC_LTE_PROFILE} component={NocProfiles} />
      {/* <PrivateRoute exact path={PATHS.NOC_LTE_SUBSCRIBERS} component={NOCSubscribers} /> */}
      <PrivateRoute exact path={PATHS.NOC_LTE_EXPIRED_SUBSCRIBERS} component={NocExpiredSubscribers} />
      <PrivateRoute exact path="/network-operations/request-feature" component={NetworkOperationsFeature} />
      <PrivateRoute exact path="/network-operations/requisitions" component={NetworkOperationsRequisition} />
      <PrivateRoute exact path="/network-operations/voltage-monitor" component={NetworkOperationsVoltage} />
      <PrivateRoute exact path="/basestations-monitoring" component={NetworkOperationsBaseStationMonitoring} />
      <PrivateRoute exact path="/network-operations/latency-graph" component={LatencyGraph} />
      <PrivateRoute exact path="/network-operations/data-logger" component={DeviceLoggerOverview} />
      <PrivateRoute exact path="/network-operations/bandwidth-graph" component={BandwidthGraph} />
      <PrivateRoute exact path="/network-operations/retrieval" component={NocRetrievals} />
      <PrivateRoute exact path="/network-operations/no-los" component={NetworkOperationsNoLos} />
      {/* <PrivateRoute exact path="/network-operations/incident-report" component={IncidentReport} /> */}
      <PrivateRoute exact path="/network-operations/incident-report-new" component={IncidentReportNew} />
      <PrivateRoute exact path="/network-operations/solar-count" component={NocSolarCount} />
      <PrivateRoute exact path="/network-operations/power-consumption" component={NocPowerConsumption} />
      <PrivateRoute exact path="/network-operations/dedicated-customers" component={DedicatedCustomers} />
      <PrivateRoute exact path="/network-operations/mail-blast" component={NetworkOperationsMailBlast} />
      <PrivateRoute exact path="/network-operations/promoter-score" component={NocPromoterScore} />
      <PrivateRoute exact path="/network-operations/kpi-monitor" component={NocKpiMonitor} />
      <PrivateRoute exact path="/network-operations/customers" component={NocCustomers} />
      <PrivateRoute exact path="/network-operations/static-ip" component={StaticIp} />
      <PrivateRoute exact path="/network-operations/schedule" component={NocSchedule} />
      <PrivateRoute exact path="/network-operations/escalate" component={NocEscalate} />
      <PrivateRoute exact path="/network-operations/fibre-links" component={FibreLinks} />
      <PrivateRoute exact path="/network-operations/tickets" component={Tickets} />
      <PrivateRoute exact path="/network-operations/noc-kpi" component={Kpi} />
      <PrivateRoute exact path="/network-operations/admin-requests" component={NocRequests} />
      <PrivateRoute exact path="/network-operations/learning-portal" component={NocLearningPortal} />
      <PrivateRoute exact path="/network-operations/purchase-order" component={NocPurchaseOrder} />
      <PrivateRoute exact path={PATHS.NOC_PAYSLIP} component={NocPayslip} />
      <PrivateRoute exact path={PATHS.NOC_BUDGET} component={NocBudget} />
      <PrivateRoute exact path={PATHS.NOC_ACCESS_POINT_CONNECTION} component={AccessPointConnection} />
      <PrivateRoute exact path={PATHS.NOC_HOTSPOT_SERVER} component={HotspotServer} />
      <PrivateRoute exact path={PATHS.NOC_FACEBOOK_SITES} component={FacebookSites} />
      <PrivateRoute exact path={PATHS.NOC_ENB_SITE_IP_ALLOCATION} component={EnbSiteIpAllocations} />
      <PrivateRoute exact path={PATHS.NOC_PTP} component={Ptp} />
      <PrivateRoute exact path={PATHS.NOC_PTP_VLAN_IP} component={PtpVlanIp} />
      <PrivateRoute exact path={PATHS.NOC_ROUTER_IP_ADDRESS} component={RouterIpAddress} />
      <PrivateRoute exact path={PATHS.NOC_SECTOR_ALLOCATION} component={SectorAllocation} />
      <PrivateRoute exact path={PATHS.NOC_SNMP_CACTI} component={SnmpCacti} />
      <PrivateRoute exact path={PATHS.NOC_SNMP} component={Snmp} />
      <PrivateRoute exact path={PATHS.NOC_SHEET_DEDICATED_CUSTOMER} component={SheetDedicatedCustomer} />
      <PrivateRoute exact path={PATHS.NOC_BASE_STATION_CONNECTION} component={BasestationConnection} />
      <PrivateRoute exact path={PATHS.NOC_FIBER_PPTP} component={FiberPptp} />
      <PrivateRoute exact path={PATHS.NOC_CNMAESTRO_TOWERS} component={Towers} />
      <PrivateRoute exact path={PATHS.NOC_CNMAESTRO_ALARMS} component={Alarms} />
      <PrivateRoute exact path={PATHS.NOC_CNMAESTRO_STATISTICS} component={Statistics} />
      <PrivateRoute exact path={PATHS.NOC_CNMAESTRO_DEVICES} component={Devices} />
      <PrivateRoute exact path={PATHS.NOC_CNMAESTRO_PERFORMANCE} component={DevicePerformances} />
      <PrivateRoute exact path={PATHS.NOC_CNMAESTRO_NETWORKS} component={Networks} />
      <PrivateRoute exact path={PATHS.NOC_CNMAESTRO_SITES} component={Sites} />
      <PrivateRoute exact path={PATHS.NOC_COMPLETED_SITES} component={CompletedSites} />
      <PrivateRoute exact path={PATHS.NOC_SUB_BASESTATION} component={SubBasestation} />
      <PrivateRoute exact path={PATHS.NOC_LISTS_OF_BASESTATIONS} component={ListOfBasestations} />
      <PrivateRoute exact path={PATHS.NOC_PUBLIC_IP_ALLOCATION} component={PublicIpAllocations} />
      <PrivateRoute exact path={PATHS.NOC_ALLOCATION_PTP} component={IpAllocationPtp} />
      <PrivateRoute exact path={PATHS.NOC_IP_ALLOCATION} component={IpAllocation} />
      <PrivateRoute exact path={PATHS.NOC_FIBER_SITES_PUBLIC_IP} component={FiberSitesPublicIp} />
      <PrivateRoute exact path={PATHS.NOC_STATIC_IP_CUSTOMERS} component={StaticIpCustomers} />
      <PrivateRoute exact path={PATHS.NOC_LTE_ENB_IP} component={LteEnbIp} />
      <PrivateRoute exact path={PATHS.NOC_CPU_UTILIZATION_OF_FIBER_SITES} component={CpuUtilization} />
      <PrivateRoute exact path={PATHS.NOC_STATIC_ACCESS_POINTS} component={StaticAccessPoints} />
      <PrivateRoute exact path={PATHS.NOC_REBOOT} component={Reboot} />
      <PrivateRoute exact path={PATHS.NOC_CAMBIUM_LICENSE_KEYS} component={CambiumLicenseKeys} />
      {/* <PrivateRoute exact path={PATHS.NOC_ENB_SITE_IP_ALLOCATION} component={Ptp} /> */}
      {/* Network Operations end */}

      {/* New Products start */}
      <PrivateRoute exact path="/new-products" component={NewProductOverview} />
      <PrivateRoute exact path="/products" component={ProductModal} />
      <PrivateRoute exact path="/new-products/issue" component={NewProductIssue} />
      <PrivateRoute exact path="/new-products/request-feature" component={NewProductFeature} />
      <PrivateRoute exact path="/new-products/requisition" component={NewProductsRequisition} />
      <PrivateRoute exact path="/new-products/mail-blast" component={NewProductsMailBlast} />
      <PrivateRoute exact path="/new-products/customers-extensions" component={NewProductsCustomerExtension} />
      <PrivateRoute exact path="/new-products/kpi-monitor" component={NewProductsKpiMonitor} />
      <PrivateRoute exact path="/new-products/geniatech" component={NewProductGeniatech} />
      <PrivateRoute exact path={PATHS.NEW_PRODUCTS_RECRUITMENT} component={NewProductRecruitment} />
      <PrivateRoute exact path={PATHS.NEW_PRODUCTS_PAYSLIP} component={NewProductsPayslip} />
      <PrivateRoute exact path="/new-products/admin-requests" component={NewProductsRequests} />
      <PrivateRoute exact path={PATHS.NEW_PRODUCTS_BUDGET} component={NewProductsBudget} />
      {/* New Products end */}

      {/* operations starts */}
      {/* <PrivateRoute exact path="/operations" component={OperationsOverview} /> */}
      {/* <PrivateRoute exact path="/operations/customers" component={OperationsCustomer} /> */}
      {/* <PrivateRoute exact path="/operations/team" component={FieldSupportTeam} /> */}
      {/* <PrivateRoute exact path="/operations/inventories" component={OpsInventories} /> */}
      {/* <PrivateRoute exact path="/operations/installations" component={OperationsInstallations} /> */}
      {/* <PrivateRoute exact path="/operations/vehicles" component={OperationsVehicles} /> */}
      {/* <PrivateRoute exact path="/operations/basestations" component={OperationsBaseStations} /> */}
      {/* <PrivateRoute exact path="/operations/leads" component={OperationsLead} /> */}
      {/* <PrivateRoute exact path="/operations/zones" component={OperationsZones} /> */}
      {/* <PrivateRoute exact path="/operations/mail-blast" component={OperationsMailBlast} /> */}
      {/* <PrivateRoute exact path="/operations/promoter-score" component={OperationsNetPromoterScore} /> */}
      {/* <PrivateRoute exact path="/issue" component={OperationsIssue} /> */}
      {/* <PrivateRoute exact path="/operations/request-feature" component={OperationsFeature} /> */}
      {/* <PrivateRoute exact path="/personnel-installation" component={OperationPersonnelInstallation} /> */}
      {/* <PrivateRoute exact path="/operations/requisition" component={OperationRequisition} /> */}
      {/* <PrivateRoute exact path="/static-access-point-operations/frequency-usage" component={FrequencyUsage}/> */}
      {/* <PrivateRoute exact path="/operations/no-no-los" component={OperationsNoLos} /> */}
      {/* <PrivateRoute exact path="/operations/kpi-monitor" component={OperationsKpiMonitor} /> */}
      {/* <PrivateRoute exact path="/operations/recruitment" component={OpsRecruitment} /> */}
      {/* <PrivateRoute exact path="/operations/vehicle-tracking" component={OpsVehicleTracking} /> */}
      {/* operations ends */}

      {/* Procurement start */}
      <PrivateRoute exact path="/procurement" component={ProcurementOverview} />
      <PrivateRoute exact path="/procurement/customers" component={ProcurementCustomers} />
      <PrivateRoute exact path="/procurement/routers" component={Routers} />
      <PrivateRoute exact path="/procurement/inventories" component={ProcurementInventories} />
      <PrivateRoute exact path="/procurement/vehicles" component={ProcurementVehicles} />
      <PrivateRoute exact path="/procurement/issue" component={ProcurementIssue} />
      <PrivateRoute exact path="/shared/release-report" component={ReleaseReport} />
      <PrivateRoute exact path="/procurement/retrievals" component={ProcurementRetrievals} />
      <PrivateRoute exact path="/procurement/request-feature" component={ProcurementFeature} />
      <PrivateRoute exact path="/procurement/installation" component={ProcurementInstallation} />
      <PrivateRoute exact path="/shared/tracklist" component={Tracklist} />
      <PrivateRoute exact path="/procurement/purchase-order" component={ProcurementPurchaseOrder} />
      <PrivateRoute exact path="/procurement/departmental-purchase-order" component={DepartmentalPurchaseOrder} />
      <PrivateRoute exact path="/procurement/approved-purchase-order" component={ProcurementApprovedPO} />
      <PrivateRoute exact path="/procurement/requisition" component={ProcurementRequisition} />
      <PrivateRoute exact path="/procurement/mail-blast" component={ProcurementMailBlast} />
      <PrivateRoute exact path="/procurement/kpi-monitor" component={ProcurementMonitor} />
      <PrivateRoute exact path={PATHS.PROCUREMENT_RECRUITMENT} component={ProcurementRecruitment} />
      <PrivateRoute exact path="/procurement/admin-requests" component={ProcurementRequests} />
      <PrivateRoute exact path="/procurement/escalate" component={ProcurementEscalate} />
      <PrivateRoute exact path="/procurement/learning-portal" component={ProcurementLearningPortal} />
      <PrivateRoute exact path="/procurement/account-reconciliation" component={ProcurementAccountReconciliation} />
      <PrivateRoute exact path={PATHS.PROCUREMENT_PAYSLIP} component={ProcurementEscalate} />
      <PrivateRoute exact path={PATHS.PROCUREMENT_TRACKLIST_CATEGORY} component={ProcurementTracklistCategory} />
      <PrivateRoute exact path={PATHS.PROCUREMENT_CLEAN_RADIO} component={ProcurementCleanRadio} />
      <PrivateRoute exact path="/procurement/dedicated-customers" component={ProcurementDedicatedCustomers} />
      {/* Procurement end */}

      {/* Research and Tech start */}
      <PrivateRoute exact path="/research-and-development" component={ResearchOverview} />
      <PrivateRoute exact path="/projects" component={ResearchProject} />
      <PrivateRoute exact path="/feature-requests" component={ResearchFeatureRequest} />
      <PrivateRoute exact path="/issue-reports" component={IssueReport} />
      <PrivateRoute exact path="/research-and-development/requisition" component={Requisition} />
      <PrivateRoute exact path="/research-and-development/scheduler" component={ResearchScheduler} />
      <PrivateRoute exact path="/research-and-development/issues" component={RandDReportIssue} />
      <PrivateRoute exact path="/research-and-development/features" component={RandDFeature} />
      <PrivateRoute exact path="/research-and-development/mail-blast" component={ResearchMailBlast} />
      <PrivateRoute exact path="/research-and-development/promoter-score" component={ResearchNetPromoterScore} />
      <PrivateRoute exact path="/research-and-development/customers" component={RandDCustomers} />
      <PrivateRoute exact path="/research-and-development/kpi-monitor" component={ResearchAndDevelopmentKpiMonitor} />
      <PrivateRoute exact path="/research-and-development/vehicle-tracking" component={VehicleTracking} />
      <PrivateRoute exact path={PATHS.RESEARCH_AND_DEV_RECRUITMENT} component={ResearchRecruitment} />
      <PrivateRoute exact path="/research-and-development/admin-requests" component={ResearchRequests} />
      <PrivateRoute exact path="/research-and-development/escalate" component={ResearchEscalate} />
      <PrivateRoute exact path="/research-and-development/payslip" component={RDPayslip} />
      <PrivateRoute exact path="/research-and-development/learning-portal" component={RDLearningPortal} />
      <PrivateRoute exact path="/research-and-development/purchase-order" component={RDPurchaseOrder} />
      <PrivateRoute exact path={PATHS.RD_BUDGET} component={RDBudget} />
      {/* Research and tech ends */}

      {/* Business Development starts */}
      <PrivateRoute
        exact
        path="/business-development/dedicated-customers"
        component={BusinessDevelopmentDedicatedCustomers}
      />
      <PrivateRoute exact path="/business-development/call-card" component={BusinessDevelopmentCallCard} />
      <PrivateRoute exact path="/business-development/customers-downtime" component={CustomerDowntimeOverview} />
      <PrivateRoute
        exact
        path="/business-development/delisted-customers"
        component={BusinessDevelopmentDelistedCustomers}
      />
      <PrivateRoute exact path="/business-development/bizdev-users" component={BusinessDevelopmentUsers} />
      <PrivateRoute
        exact
        path="/business-development/dedicated-connected-users"
        component={BusinessDevelopmentDedicatedConnectedUsers}
      />
      <PrivateRoute
        exact
        path="/business-development/basestation-status"
        component={BusinessDevelopmentBasestationStatus}
      />
      <PrivateRoute
        exact
        path="/business-development/customer-revenue"
        component={BusinessDevelopmentCustomerRevenue}
      />
      <PrivateRoute exact path="/shared/customer-details" component={CustomerDetailsShared} />

      <PrivateRoute exact path="/business-development/logs" component={BusinessDevelopmentLogs} />
      <PrivateRoute exact path="/business-development/invoice" component={BusinessDevelopmentInvoice} />
      <PrivateRoute exact path="/business-development" component={BusinessDevelopmentOverview} />

      {/* business dev ends */}

      {/* partnership starts */}
      <PrivateRoute exact path="/partnership" component={PartnershipOverview} />
      <PrivateRoute exact path="/logs" component={PartnershipLogs} />
      <PrivateRoute exact path="/usage-history" component={UsageHistory} />
      <PrivateRoute exact path="/signup-history" component={SignupHistory} />
      <PrivateRoute exact path="/active-and-inactive" component={PartnershipActiveAndInactiveCustomers} />
      <PrivateRoute exact path="/bandwidth" component={PartnershipBandwidth} />

      <PrivateRoute exact path="/device-type" component={DeviceTypes} />

      <PrivateRoute exact path="/bandwidth-schedule" component={PartnershipBandwidthSchedule} />

      {/* partnership ends */}

      {/* Asset Team */}
      {/* <PrivateRoute exact path="/asset-team" component={AssetTeamOverview} /> */}
      <PrivateRoute exact path="/closed-leads" component={AssetTeamClosedLeads} />
      <PrivateRoute exact path="/shared/statistics" component={ProductUptime} />
      <PrivateRoute exact path={PATHS.ASSET_TEAM_ACTIVE_CUSTOMERS} component={AssetTeamActiveCustomers} />
      <PrivateRoute exact path="/expired" component={AssetTeamExpiredCustomers} />
      <PrivateRoute exact path="/asset-team/expired" component={AssetTeamExpired} />
      <PrivateRoute exact path="/asset-team/relocation" component={RelocationShared} />
      <PrivateRoute exact path="/asset-team/dedicated-customers" component={AssetTeamDedicatedCustomers} />
      <PrivateRoute
        exact
        path={PATHS.ASSET_TEAM_INSTALLATIONS_PER_BASESTATIONS}
        component={AssetTeamInstallationsPerBasestation}
      />
      <PrivateRoute exact path="/asset-team/expired-zones" component={ExpiredCustomersByZones} />
      <PrivateRoute exact path="/asset-team/active-per-zone" component={AssetTeamActiveCustomersPerZone} />
      <PrivateRoute exact path="/asset-team/graphs" component={AssetGraphs} />
      <PrivateRoute exact path={PATHS.ASSET_TEAM_ACTIVE_PER_COUNTRY} component={AssetTeamActivePerCountry} />
      <PrivateRoute exact path={PATHS.ASSET_TEAM_EXPIRED_PER_COUNTRY} component={AssetTeamExpiredCountry} />
      <PrivateRoute exact path={PATHS.ASSET_TEAM_INSTALLED_PER_COUNTRY} component={AssetTeamInstalledCountry} />
      <PrivateRoute exact path={PATHS.ASSET_TEAM_ACTIVE_AND_INACTIVE} component={AssetTeamActiveAndInactive} />
      <PrivateRoute exact path={PATHS.ASSET_TEAM_EXPIRED_LTE_CUSTOMERS} component={AssetTeamExpiredLteCustomers} />
      <PrivateRoute exact path={PATHS.ASSET_TEAM_ACTIVE_LTE_CUSTOMERS} component={AssetTeamActiveLteCustomers} />
      <PrivateRoute exact path={PATHS.ASSET_TEAM_LTE_INSTALLATIONS} component={AssetLTEInstallation} />
      <PrivateRoute exact path={PATHS.ASSET_TEAM_DEVICE_RETRIEVAL} component={AssetDeviceRetrieval} />
      <PrivateRoute exact path={PATHS.ASSET_TEAM_TRACKER} component={AssetTeamCustomersTracker} />
      <PrivateRoute exact path={PATHS.ASSET_TEAM_GENIATECH} component={AssetTeamGeniatech} />
      <PrivateRoute exact path={PATHS.ASSET_TEAM_PAYSLIP} component={AssetTeamPayslip} />
      <PrivateRoute exact path={PATHS.ASSET_TEAM_KPI_MONITOR} component={AssetTeamKpiMonitor} />

      {/* sales start */}
      <PrivateRoute exact path="/sales" component={SaleOveriew} />
      <PrivateRoute exact path="/sales/logs" component={SalesLogHeader} />
      <PrivateRoute exact path="/sales/basestations" component={SalesBaseStation} />
      <PrivateRoute exact path="/sales/installations" component={SalesInstallations} />
      <PrivateRoute exact path="/sales/relocation/pending-relocation" component={PendingRelocation} />
      <PrivateRoute exact path="/sales/referral" component={SalesReferral} />
      <PrivateRoute exact path="/sales/customers" component={SalesCustomers} />
      <PrivateRoute exact path="/sales/usage-history" component={SalesUsageHistory} />
      <PrivateRoute exact path={PATHS.SALES_CUSTOMER_INVOICE} component={SalesCustomerInvoice} />
      <PrivateRoute exact path={PATHS.SALES_EXISTING_INVOICE} component={SalesExistingInvoice} />
      <PrivateRoute exact path="/sales/leads" component={SalesLead} />
      <PrivateRoute exact path="/sales/issue" component={SalesIssue} />
      <PrivateRoute exact path="/sales/request-feature" component={SalesFeature} />
      <PrivateRoute exact path="/sales/requisition" component={SalesRequisition} />
      <PrivateRoute exact path="/sales/escalate" component={SalesEscalate} />
      <PrivateRoute exact path="/sales/relocation" component={SalesRelocation} />
      <PrivateRoute exact path="/sales/mail-blast" component={SalesMailBlast} />
      <PrivateRoute exact path="/sales/kpi-monitor" component={SalesKpiMonitor} />
      <PrivateRoute exact path={PATHS.SALES_RECRUITMENT} component={SalesRecruitment} />
      <PrivateRoute exact path="/sales/admin-request" component={SalesRequests} />
      <PrivateRoute exact path="/failed-installations" component={SalesFailedInstallations} />
      <PrivateRoute exact path="/sales/connected-customers" component={ConnectedCustomers} />
      <PrivateRoute exact path="/sales/learning-portal" component={SalesLearningPortal} />
      <PrivateRoute exact path="/sales/purchase-order" component={SalesPurchaseOrder} />
      <PrivateRoute exact path="/sales/bbn-signups" component={SalesBBNSignups} />
      <PrivateRoute exact path="/sales/wifi-events" component={SalesWifiEvent} />
      <PrivateRoute exact path="/sales/jackets" component={SalesJacket} />
      <PrivateRoute exact path={PATHS.SALES_KYC} component={SalesLteKyc} />
      <PrivateRoute exact path={PATHS.SALES_PAYSLIP} component={SalesPayslip} />
      <PrivateRoute exact path={PATHS.SALES_BUDGET} component={SalesBudget} />
      <PrivateRoute exact path="/schedule" component={ScheduleUser} />
      <PrivateRoute exact path={PATHS.SCHEDULE_USER} component={NewScheduleUser} />
      <PrivateRoute exact path={PATHS.SALES_DEDICATED_CUSTOMERS} component={SalesDedicatedCustomers} />
      <PrivateRoute exact path={PATHS.SALES_ASSET_TEAM_INSTALLATIONS} component={SalesAssetTeamInstallations} />
      <PrivateRoute exact path={PATHS.SALES_RESCHEDULED_INSTALLATIONS} component={SalesRescheduledInstallations} />
      <PrivateRoute exact path={PATHS.SALES_CLUSTERED_INSTALLATIONS} component={SalesClusteredInstallations} />
      <PrivateRoute
        exact
        path={PATHS.SALES_INSTALLATIONS_PER_BASESTATION}
        component={SalesInstallationsPerBasestation}
      />
      <PrivateRoute exact path={PATHS.SALES_REFUND} component={RefundFlow} />
      <PrivateRoute exact path={PATHS.SALES_INSTALLATIONS_DATE_RANGE} component={SalesInstallationsPerDateRange} />
      <PrivateRoute
        exact
        path={PATHS.SALES_INSTALLATIONS_COUNTER_SUMMARY}
        component={SalesInstallationsCounterSummary}
      />
      {/* sales end */}

      {/* Tech support start */}
      {/* <PrivateRoute exact path="/technical-support" component={TechSupportOverview} /> */}
      {/* <PrivateRoute exact path="/technical-support/transactions" component={TechSupportTransactions} /> */}
      {/* <PrivateRoute exact path="/technical-support/customers" component={TechSupportCustomers} /> */}
      {/* <PrivateRoute exact path="/technical-support/usage-history" component={TechSupportUsageHistory} /> */}
      {/* <PrivateRoute exact path="/technical-support/retention" component={TechSupportRetention} /> */}
      {/* <PrivateRoute exact path="/technical-support/team" component={TechSupportTeam} /> */}
      {/* <PrivateRoute exact path="/technical-support/schedule" component={TechSupportSchedule} /> */}
      {/* <PrivateRoute exact path="/technical-support/Issue" component={TechIssue} /> */}
      {/* <PrivateRoute exact path="/technical-support/request-feature" component={TechFeatures} /> */}
      {/* <PrivateRoute exact path="/technical-support/requisition" component={TechRequisition} /> */}
      {/* <PrivateRoute exact path="/technical-support/mail-blast" component={TechSupportMailBlast} /> */}
      {/* <PrivateRoute exact path="/technical-support/kpi-monitor" component={TechKpiMonitor} /> */}
      {/* <PrivateRoute exact path="/technical-support/recruitment" component={TechSupportRecruitment} /> */}
      {/* Tech support ends */}

      <Route exact path="/hotspot-admin" component={HotspotAdminOverview} />
      <Route exact path="/hotspot-admin/retailers" component={HotspotAdminRetailer} />
      <Route exact path="/hotspot-admin/empty-state" component={HotspotAdminEmpty} />
      <Route exact path="/hotspot-admin/transaction" component={HotspotAdminTransaction} />
      <Route exact path="/hotspot-admin/fund-wallet" component={HotspotAdminFundWallet} />

      {/* WifiCall start */}
      <PrivateRoute exact path="/wifi-call" component={WifiCallOverview} />
      <PrivateRoute exact path="/wifi-call/plans" component={WifiCallPlan} />
      <PrivateRoute exact path="/wifi-call/customers" component={VerifiedCustomers} />
      <PrivateRoute exact path="/wifi-call/directory" component={WifiCallDirectory} />
      {/* <PrivateRoute exact path="/wifi-call/payments" component={WifiCallPayment} /> */}
      <PrivateRoute exact path="/wifi-call/wallboard" component={Wallboard} />
      <PrivateRoute exact path="/wifi-call/wallet" component={WalletRefill} />
      <PrivateRoute exact path="/wifi-call/verify" component={WifiCallVerify} />
      <PrivateRoute exact path="/wifi-call/issue" component={WifiCallIssue} />
      <PrivateRoute exact path="/wifi-call/features" component={WifiCallFeature} />
      <PrivateRoute exact path="/wifi-call/unverified-customers" component={UnverifiedCustomers} />
      <PrivateRoute exact path="/wifi-call/count" component={Count} />
      <PrivateRoute exact path="/wifi-call/escalate" component={WifiCallEscalate} />
      <PrivateRoute exact path="/wifi-call/learning-portal" component={WifiCallLearningPortal} />
      <PrivateRoute exact path="/wifi-call/active-inactive-customers" component={WifiCallActiveAndInactiveCustomers} />
      <PrivateRoute exact path="/wifi-call/assigned-unassigned" component={WifiCallAssignedAndUnassigned} />
      <PrivateRoute exact path="/wifi-call/payments" component={WifiCallPayments} />
      <PrivateRoute exact path="/wifi-call/send-invoices" component={SendInvoices} />
      <PrivateRoute exact path="/wifi-call/login-logs" component={WifiCallLoginLogs} />
      <PrivateRoute exact path="/wifi-call/purchase-order" component={WifiCallPurchaseOrder} />
      <PrivateRoute exact path="/wifi-call/frequency" component={FrequencyHistory} />
      <PrivateRoute exact path={PATHS.WIFICALL_FREE_SETUP_SIGNUP} component={FreeSignUps} />
      <PrivateRoute exact path={PATHS.WIFICALL_OPEN_BLOCKED_USERS} component={OpenAndBlockedUsers} />
      {/* WifiCall ends */}

      {/* NewDesign start */}

      <PrivateRoute exact path="/new-design/overview" component={NewDesignOverview} />
      <PrivateRoute exact path="/new-design/installations" component={NewDesignInstallation} />
      <PrivateRoute exact path="/new-design/installation-zones" component={NewDesignInstallationZone} />
      <PrivateRoute exact path="/actions/:id" component={NewDesignInstallationAction} />
      <PrivateRoute exact path="/zone-actions/:id" component={NewDesignInstallationZoneAction} />
      <PrivateRoute exact path="/new-design/installation-counter" component={NewDesignInstallationCounter} />
      {/* NewDesign ends */}

      {/* business dev starts */}
      <Route
        exact
        path={PATHS.BUSINESS_DEVELOPMENT_DEDICATED_CUSTOMERS}
        component={BusinessDevelopmentDedicatedCustomers}
      />
      <PrivateRoute exact path={PATHS.BUSINESS_DEVELOPMENT_KPI_MONITOR} component={BusinessDevelopmentKpiMonitor} />
      <PrivateRoute exact path="/business-development/usage-history" component={BusinessDevelopmentUsageHistory} />

      <PrivateRoute exact path={PATHS.BUS_DEV_NIGERIA_REQUISITION} component={BusinessDevelopmentRequisition} />
      <PrivateRoute exact path={PATHS.BUSINESS_DEVELOPMENT_PAYSLIP} component={BusinessDevelopmentPayslip} />
      {/* business dev ends */}

      <PrivateRoute exact path="/dummy" component={Dummy} />
      <Route exact path="/requisition/:id" component={ViewOneRequisition} />

      {/* 404 page */}
      <Route component={NotFound} />
      {/* 404 ends */}
    </Switch>
  </App>
);

export default Routes;
