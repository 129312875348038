/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import { useCustomer } from '../../../../../hooks/shared/customers/useCustomers';
import { useUser } from '../../../../../hooks/shared/user/useUser';

const Extend = ({ username, name }) => {
  const { onExtendChange, extend, extendCustomer, extendingCustomer } = useCustomer();
  const { duration, reason } = extend;
  const { profile } = useUser();
  const { officialEmail } = profile;

  const onSubmit = (e) => {
    e.preventDefault();

    if (Number(sessionStorage.getItem('roleId')) > 2 && Number(sessionStorage.getItem('roleId')) !== 6) {
      const data = {
        username,
        duration,
      };
      const extendLog = {
        macid: username,
        extending_days: duration,
        comment: reason,
        name,
        extension_type: 'extension',
      };

      extendCustomer(data, extendLog);
    } else if (officialEmail === 'kikelomo.azeez@tizeti.com') {
      const data = {
        username,
        duration,
      };
      const extendLog = {
        macid: username,
        extending_days: duration,
        comment: reason,
        name,
        extension_type: 'extension',
      };

      extendCustomer(data, extendLog);
    } else {
      const data = {
        username,
        duration: '1',
      };
      const extendLog = {
        macid: username,
        extending_days: '1',
        comment: reason,
        name,
        extension_type: 'extension',
      };

      extendCustomer(data, extendLog);
    }
  };

  const submitForm = () => {
    return duration.length > 0 && reason.length > 0;
  };
  console.log(sessionStorage.getItem('roleId'));
  const checkRoleForExtension = () => {
    if (
      Number(sessionStorage.getItem('roleId')) == 3 ||
      Number(sessionStorage.getItem('roleId')) == 4 ||
      Number(sessionStorage.getItem('roleId')) == 5 ||
      Number(sessionStorage.getItem('roleId')) == 9 ||
      officialEmail === 'kikelomo.azeez@tizeti.com'
    ) {
      return (
        <div className="modal-body">
          <form className="form-horizontal" onSubmit={onSubmit}>
            <div className="form-group">
              <div className="col-12 mb-3 d-flex">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Number of days to extend with"
                  name="duration"
                  value={duration}
                  onChange={onExtendChange}
                />

                <input
                  type="text"
                  className="form-control"
                  placeholder="Reason for extension"
                  name="reason"
                  value={reason}
                  onChange={onExtendChange}
                />
              </div>

              {extendingCustomer ? (
                <div className="col-12 mb-3 text-center">
                  <button className="btn btn-green" disabled={extendingCustomer}>
                    Extend Customer
                  </button>
                </div>
              ) : (
                <div className="col-12 mb-3 text-center">
                  <button className="btn btn-green" disabled={!submitForm()}>
                    Extend Customer
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      );
      // eslint-disable-next-line no-else-return
    }
    return (
      <div className="modal-body">
        <form className="form-horizontal" onSubmit={onSubmit}>
          <div className="form-group">
            <div className="col-12 mb-3 d-flex">
              <input
                type="text"
                className="form-control"
                placeholder="Number of days to extend with"
                name="duration"
                value="1"
                onChange={onExtendChange}
                readOnly
              />

              <input
                type="text"
                className="form-control"
                placeholder="Reason for extension"
                name="reason"
                value={reason}
                onChange={onExtendChange}
              />
            </div>

            {extendingCustomer ? (
              <div className="col-12 mb-3 text-center">
                <button className="btn btn-green" disabled={extendingCustomer}>
                  Extend Customer
                </button>
              </div>
            ) : (
              <div className="col-12 mb-3 text-center">
                <button className="btn btn-green">Extend Customer</button>
              </div>
            )}
          </div>
        </form>
      </div>
    );
  };
  return (
    <div
      id="extend"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Extend Customer
            </h4>
            <button type="button" className="close custom-close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>
          {checkRoleForExtension()}
        </div>
      </div>
    </div>
  );
};

Extend.propTypes = {
  name: PropTypes.string,
  username: PropTypes.string,
};
export default Extend;
