/* eslint-disable react/require-default-props */
/* eslint-disable no-shadow */
/* eslint-disable no-case-declarations */
/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { useCustomer } from '../../../../../hooks/shared/customers/useCustomers';
import { usePayments } from '../../../../../hooks/shared/customers/usePayments';
import PaymentsHistory from '../../../shared/customers/payments/PaymentsHistory';
import Rollback from '../../../shared/customers/extensions/Rollback';
import Extend from '../../new-products/customers/Extend';
import { displayCountryDropdown } from '../../../../../utils/geniatech/Geniatech';
import edit from '../../../../../assets/img/icons/edit.png';
import CleanRadio from '../../procurement/customers/CleanRadio';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import view from '../../../../../assets/img/icons/view.png';
import ExtendCustomer from '../../../shared/customers/extensions/ExtendCustomer';

const AccountsGeniatechTable = () => {
  const {
    search,
    onSearchChange,
    searchForUserByEmail,
    searchByPhoneOrMac,
    searchByName,
    customers,
    isLoading,
    option,
    onOptionChange,
    country,
    onCountryChange,
    searchByReference,
  } = useCustomer();


  const [openDeviceConfirmation, setOpenDeviceConfirmation] = useState(false);

  const onOpenDeviceConfirmation = () => setOpenDeviceConfirmation(!openDeviceConfirmation);
  const { payments, gettingPayments, getCustomerPayments } = usePayments();
  const { profile } = useUser();
  const { officialEmail } = profile;

  const [mac, setMac] = useState('');
  const [name, setName] = useState('');
  const [username, setUserName] = useState('');

  const searchForUser = (e) => {
    e.preventDefault();
    switch (option) {
      case 'email':
        const emailSearch = {
          email: search.trim(),
        };
        searchForUserByEmail(emailSearch);
        break;

      case 'name':
        const nameSearch = {
          name: search.trim(),
          country,
        };
        searchByName(nameSearch);
        break;

      case 'phone':
        const phoneSearch = {
          phoneNumber: search.trim(),
          macAddress: '12',
          category: 'phoneNumber',
        };
        searchByPhoneOrMac(phoneSearch);
        break;

      case 'macid':
        const macidSearch = {
          macAddress: search.trim(),
          phoneNumber: 'N/A',
          category: 'macaddress',
        };
        searchByPhoneOrMac(macidSearch);
        break;
      case 'reference':
        const refernceNumberSearch = {
          reference: search.trim(),
          phoneNumber: 'N/A',
          macAddress: 'N/A',
          action: 'getUserInformationWithPhoneOrMac',
          category: 'reference',
        };
        searchByReference(refernceNumberSearch);
        break;

      default:
        return null;
    }
  };

  const searchForUserReload = (option, search) => {
    switch (option) {
      case 'email':
        const emailSearch = {
          email: search.trim(),
        };
        searchForUserByEmail(emailSearch);
        break;

      case 'name':
        const nameSearch = {
          name: search.trim(),
          country,
        };
        searchByName(nameSearch);
        break;

      case 'phone':
        const phoneSearch = {
          phoneNumber: search.trim(),
          macAddress: '12',
          category: 'phoneNumber',
        };
        searchByPhoneOrMac(phoneSearch);
        break;

      case 'macid':
        const macidSearch = {
          macAddress: search.trim(),
          phoneNumber: 'N/A',
          category: 'macaddress',
        };
        searchByPhoneOrMac(macidSearch);
        break;
      case 'reference':
        const refernceNumberSearch = {
          reference: search.trim(),
          phoneNumber: 'N/A',
          macAddress: 'N/A',
          action: 'getUserInformationWithPhoneOrMac',
          category: 'reference',
        };
        searchByReference(refernceNumberSearch);
        break;

      default:
        return null;
    }
  };

  const getUser = (customer) => {
    setMac(customer.username);
    setName(customer.name);
  };

  const getUserPayments = (customer) => {
    const data = {
      macid: customer.username,
    };
    getCustomerPayments(data);
  };

  const getUserForRollback = (c) => {
    // mac
    setName(c.name);
    // name
    setUserName(c.username);
  };

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-lg-12 mt-5 mb-3">
          <form onSubmit={searchForUser} className="form-inline inner-addon d-flex">
            <div className="input-group mb-3 mr-3">
              <select name="keyword" onChange={onOptionChange} className="form-control mr-auto empty mt-1">
                <option value="">Choose fields to search from</option>
                <option value="email">Email</option>
                <option value="name">Name</option>
                <option value="phone">Phone</option>
                <option value="macid">MAC ID</option>
                <option value="reference">Account Number</option>
              </select>
            </div>

            {displayCountryDropdown(option, onCountryChange)}

            <div className="input-group mb-3 mr-3">
              <input
                className="form-control mr-auto empty pl-5"
                type="text"
                placeholder="search for user"
                name="search"
                value={search}
                onChange={onSearchChange}
              />
              <span className="fa fa-search" />
            </div>

            <div className="mb-3">
              <button className="btn btn-green" style={{ height: '39px' }}>
                Search
              </button>
            </div>
          </form>
        </div>
        <div className="card x-panel">
          <div className="table-responsive content">
            <table className="table copy-font">
              <thead>
                <tr>
                  <th>#</th>
                  <th>MAC ID</th>
                  <th nowrap="no-wrap">Name</th>
                  <th>Email</th>
                  <th>Rollback</th>
                  <th nowrap="no-wrap">Phone Number</th>
                  <th className="no-wrap">Account Number</th>
                  <th className="text-center">Extend</th>
                  <th nowrap="">Expiry Date</th>
                  {officialEmail === 'kikelomo.azeez@tizeti.com' ||
                  officialEmail === 'hakeem.balogun@tizeti.com' ||
                  officialEmail === 'ijeoma.nweke@tizeti.com' ||
                  officialEmail === 'financeDept@tizeti.com' ||
                  officialEmail === 'amanosi.okhakhu@tizeti.com' ||
                  officialEmail === 'cfo@tizeti.com' ? (
                    <th className="text-center">Clean Radio</th>
                  ) : null}
                  {/* <th className="text-center">Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <img src={loader} alt="loading gif" />
                ) : customers && customers.length ? (
                  customers.map((customer, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{customer.username}</td>
                      <td nowrap="no-wrap">{customer.name}</td>
                      <td>{customer.mail}</td>
                      <td className="ml-2">
                        <button
                          data-toggle="modal"
                          data-target="#rollback"
                          className="ml-3"
                          onClick={() => getUserForRollback(customer)}
                        >
                          <i className="view1 fa fa-ellipsis-v" style={{ fontSize: '20px' }} title="Rollback" />
                        </button>
                        {/* {get ? <h1>loading</h1> : <PaymentHistory usersTransactions={this.state.transactions}/>} */}
                        {/* <PaymentHistory usersTransactions={this.state.transactions} get={get}/> */}
                        <Rollback username={username} name={name} />
                      </td>
                      <td>{customer.address}</td>
                      <td>{customer.customer_ref}</td>

                      <td
                        className="text-center img-pointer"
                        data-target="#extend"
                        onClick={() => getUser(customer)}
                        data-toggle="modal"
                      >
                        <span className="view text-center">
                          <img src={view} data-toggle="tooltip" alt="Extend" title="Extend" />
                        </span>
                      </td>
                      <ExtendCustomer username={mac} name={name} />
                      <td>{customer.value}</td>
                      {officialEmail === 'kikelomo.azeez@tizeti.com' ||
                      officialEmail === 'hakeem.balogun@tizeti.com' ||
                      officialEmail === 'ijeoma.nweke@tizeti.com' ||
                      officialEmail === 'financeDept@tizeti.com' ||
                      officialEmail === 'amanosi.okhakhu@tizeti.com' ||
                      officialEmail === 'cfo@tizeti.com' ? (
                        <td
                          className="text-center img-pointer"
                          // data-target="#clean-radio"
                          onClick={() => {
                            getUser(customer);
                            onOpenDeviceConfirmation();
                          }}
                          // data-toggle="modal"
                          style={{ width: '40%' }}
                        >
                          <span className="view2">
                            <img src={edit} alt="" data-toggle="tooltip" data-placement="top" title="Edit" />
                          </span>
                        </td>
                      ) : null}
                      <CleanRadio
                        username={mac}
                        show={openDeviceConfirmation}
                        onClose={onOpenDeviceConfirmation}
                        searchForUserReload={searchForUserReload}
                        option={option}
                        search={search}
                      />
                      {/* <td className="ml-2">
                        <button
                          data-toggle="modal"
                          data-target="#payment-history"
                          className="ml-4"
                          onClick={() => getUserPayments(customer)}
                        >
                          <i className="view1 fa fa-ellipsis-v" style={{ fontSize: '20px' }} title="Payment History" />
                        </button>
                        {/* <PaymentHistory /> */}

                      {/* <PaymentsHistory load={gettingPayments} transactions={payments} /> */}
                      {/* <button */}
                      {/*  type="submit" */}
                      {/*  style={{ */}
                      {/*    border: '0', */}
                      {/*    backgroundColor: 'white', */}
                      {/*    width: '0%', */}
                      {/*  }} */}
                      {/*  data-toggle="tooltip" */}
                      {/*  data-placement="left" */}
                      {/*  title="View" */}
                      {/*  onClick={() => getUserDetails(customer.username)} */}
                      {/* > */}
                      {/*    <span className="view1 ml-4"> */}
                      {/*      <img */}
                      {/*        src={view} */}
                      {/*        alt="" */}
                      {/*        data-toggle="modal" */}
                      {/*        data-target="#cus-details" */}
                      {/*      /> */}
                      {/*    </span> */}
                      {/* </button> */}
                      {/* <CustomerDetails */}
                      {/*  load={gettingDetails} */}
                      {/*  address={address} */}
                      {/*  details={details} */}
                      {/*  session={session} */}
                      {/* /> */}
                      {/* </td> */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      No customer found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AccountsGeniatechTable;
